import { IncomeType } from "library";
import { HousingType, YesNo, YesNoSilent } from "library/enums";

interface ICircumstances {
	housingType?: HousingType;
	experiencedRecently: YesNo;
	reasonForBenefit: string;
	communityHousing: YesNoSilent;
	longTermHousingPlan: YesNoSilent;
	safeSpace: YesNoSilent;
	subsidized: YesNoSilent;
	underThreat: YesNoSilent;
	incomeSource?: IncomeType;
}

class Circumstances implements ICircumstances {
	readonly housingType?: HousingType;
	readonly experiencedRecently: YesNo;
	readonly reasonForBenefit: string;
	readonly communityHousing: YesNoSilent;
	readonly longTermHousingPlan: YesNoSilent;
	readonly safeSpace: YesNoSilent;
	readonly subsidized: YesNoSilent;
	readonly underThreat: YesNoSilent;
	
	constructor(from?: Partial<ICircumstances>) {
		this.housingType = from?.housingType;
		this.experiencedRecently = from?.experiencedRecently ?? YesNo.No;
		this.reasonForBenefit = from?.reasonForBenefit ?? "";
		this.communityHousing = from?.communityHousing ?? YesNoSilent.PreferNotToSay;
		this.longTermHousingPlan = from?.longTermHousingPlan ?? YesNoSilent.PreferNotToSay;
		this.safeSpace = from?.safeSpace ?? YesNoSilent.PreferNotToSay;
		this.subsidized = from?.subsidized ?? YesNoSilent.PreferNotToSay;
		this.underThreat = from?.underThreat ?? YesNoSilent.PreferNotToSay;
	}
}

export { Circumstances };
export type { ICircumstances };
