import styles from "styles/application.module.scss";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, TextField, Typography } from "@mui/material";
import { Actions, TextArea } from "components";
import { Application, ApplicationType, CurrencyDisplay, DisbursementAmount, DisbursementAmounts, fullWidth, Note, oneThirdWidth } from "library";
import { ReactNode, useMemo, useState } from "react";

interface IKeyDataProps {
    value: Application;
    readonly?: boolean;
    onChange: (value: Partial<Application>, notes: Note) => Promise<void>;
}

const ApplicationKeyData: (props: IKeyDataProps) => ReactNode = ({value, readonly, onChange}) => {
    const labelStyle = {label: styles.label, root: styles.label};

    const [processing, setProcessing] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editValue, setEditValue] = useState<DisbursementAmounts>(value.amounts);
    const [dialog, setDialog] = useState<{ display: boolean, notes?: string, error?: string }>({display: false});

    const onCancel = () => {
        setEditValue(value.amounts);
        setEditMode(false);
    };

    const remainsUnchanged = useMemo(() =>
        !Object.entries(editValue).some(([k, v]) => editValue[k].amount !== value.amounts[k]?.amount), [editValue, value]);

    const onDialogClose = () => setDialog({display: false});
    
    const onSave = () => {
        if (!onChange || !editValue || remainsUnchanged) {
            setEditMode(false);
            return;
        }

        setDialog({display: true});
    }

    const onSubmit = () => {
        if (!dialog.notes?.length) {
            setDialog({...dialog, error: "A note value must be provided."});
            return;
        }

        setProcessing(true);
        onChange({amounts: new DisbursementAmounts({...editValue, notes: undefined})}, new Note({
            subject: "Subsidy Amounts Changed",
            message: `${Object.entries(editValue).map(([k, v]) => `${k}: ${CurrencyDisplay(v.amount)}`).join(" ")}\r\n${dialog.notes}`,
            internal: true
        }))
            .then(() => setEditMode(false))
            .finally(() => {
                setDialog({display: false})
                setProcessing(false)
            });
    }
    const bedrooms = (value: number) => (value ?? -1) >= 0 ? `${value}` : "-";
    const isGbv = value.isGbv;

    return (
        <Grid container className={`${styles.keyData}`}>
            <Grid container>
                <Grid item {...fullWidth}><Typography variant={"h3"} className={styles.formLabel}>Key Data</Typography></Grid>
                {value.type === ApplicationType.Standard &&
                <Grid item {...oneThirdWidth} className={styles.score}>
                    <FormControlLabel classes={labelStyle} labelPlacement={"top"} control={<div className={`${styles.value} ${styles.application}`}>{isGbv ? "NA" : value.pointScore.total}</div>} label={"Application Score"}/>
                </Grid> }
                <Grid item {...oneThirdWidth} className={styles.score}>
                    <FormControlLabel classes={labelStyle} labelPlacement={"top"} control={<div className={`${styles.value} ${styles.application}`}>{isGbv ? "NA" : value.household.count}</div>} label={"Household Members"}/>
                </Grid>
                <Grid item {...oneThirdWidth} className={styles.score}>
                    <FormControlLabel classes={labelStyle} labelPlacement={"top"} control={<div className={`${styles.value} ${styles.application}`}>{isGbv ? "NA" : CurrencyDisplay(value.incomeTotal)}</div>} label={"Total Income"}/>
                </Grid>
                <Grid item {...oneThirdWidth} className={styles.score}>
                    <FormControlLabel classes={labelStyle} labelPlacement={"top"} control={<div className={`${styles.value} ${styles.application}`}>{isGbv ? "NA" : bedrooms(value.calculatedBedroomCount)}</div>} label={"Calculated Bedrooms"}/>
                </Grid>
                <Grid item {...oneThirdWidth} className={styles.score}>
                    <FormControlLabel classes={labelStyle} labelPlacement={"top"} control={<div className={`${styles.value} ${styles.application}`}>{isGbv ? "NA" : bedrooms(value.bedroomCount)}</div>} label={"Inputted Bedrooms"}/>
                </Grid>
                <Grid item {...oneThirdWidth} className={styles.score}>
                    <FormControlLabel classes={labelStyle} labelPlacement={"top"} control={<div className={`${styles.value} ${styles.application}`}>{isGbv ? "NA" : CurrencyDisplay(value.incomeTotal / 12)}</div>} label={"Total Monthly Income"}/>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item {...fullWidth}><
                    Typography variant={"h3"} className={styles.formLabel}>
                    Subsidy Amounts
                    {!readonly && <Actions processing={processing} editMode={editMode} onEdit={() => setEditMode(true)} onCancel={onCancel} onSave={onSave}/>}
                </Typography>
                </Grid>

                {value.programs.map(p => (
                    <Grid key={p} item {...oneThirdWidth} className={styles.score}>
                        <FormControlLabel classes={labelStyle}
                                          labelPlacement={"top"}
                                          control={!editMode
                                              ? <div className={`${styles.value} ${styles.application}`}>{CurrencyDisplay(value.amounts[p.toUpperCase()]?.amount ?? 0)}</div>
                                              : <TextField type={"number"} value={editValue[p.toUpperCase()]?.amount ?? 0} disabled={processing}
                                                           onChange={e => setEditValue(new DisbursementAmounts({...editValue, [p]: new DisbursementAmount({amount: e.target.value ? Number(e.target.value) : 0, calculated: false})}))}/>}
                                          label={p === "GBV" ? "Gender Based Violence" : `${p} Subsidy Amount`}
                        />
                    </Grid>
                ))}
            </Grid>
            <Dialog open={dialog.display} onClose={onDialogClose}>
                <DialogTitle>{"Override"}</DialogTitle>
                <DialogContent>
                    Please provide a reason (notes) for overriding the subsidy amount(s).
                    <FormControl fullWidth>
                        <TextArea label={"Notes"} className={styles.fullWidth} rows={5} error={!!dialog.error} value={dialog.notes}
                                  onChange={v => setDialog({...dialog, notes: v, error: undefined})}/>
                        <FormHelperText error={!!dialog.error}>{dialog.error}</FormHelperText>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button disabled={processing} onClick={onDialogClose}>Cancel</Button>
                    <Button disabled={processing} onClick={onSubmit}>Save</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export {
    ApplicationKeyData
};
