import {createSlice} from "@reduxjs/toolkit";

type Program = { shortName: string, longName: string, bgcolor?: string };

type Programs = {
    defaultProgram: Program;
    programs: Array<Program>
}

const initialState: Programs = {
    defaultProgram: {shortName: "RAB", longName: "Rental Assistance Benefit (RAB)"},
    programs: [
        {shortName: "RAB", longName: "Rental Assistance Benefit (RAB)"},
        {shortName: "TRAB", longName: "Temporary Rental Assistance Benefit (TRAB)"},
        {shortName: "GBV", longName: "Gender Based Violence (GBV)", bgcolor: "#F8BBD0"},
    ]
};

const programs = createSlice({
    name: "programs",
    initialState,
    reducers: {}
});

export default programs.reducer;
export type {
    Program,
    Programs
}