import {Application, Household, Person} from "../application";
import {Rates} from "./rates";
import {CoreShelterRateRule} from "./coreShelterRateRule";
import {RabRateRule} from "./rab";
import {TrabRateRule} from "./trab";
import { ApplicationType } from "library/enums";

abstract class RateCalculator {
    protected constructor(readonly rates: Rates | undefined) {
    }

    static for(rates: Rates | undefined, service: string) {
        switch (service) {
            case "RAB":
                return new RabRateCalculator(rates);
            case "TRAB":
                return new TrabRateCalculator(rates);
            case "GBV":
                return new GbvCalculator(rates);
            default:
                return null;
        }
    }

    abstract amountFor(application: Application, correctTotals: boolean): number;
}

class RabRateCalculator extends RateCalculator {
    amountFor(application: Application, correctTotals = false): number {
        const rabRate = [...(this.rates?.["RAB"] as RabRateRule[] ?? [])]
            .filter(r => (r.bedrooms ?? 0) <= application.bedrooms)
            .sort((a, b) => (a.bedrooms ?? 0) - (b.bedrooms ?? 0))
            .pop();
        if (!rabRate) return 0;

        const reductionAmount = application.includesIncomeSupport
            ? this.getCoreShelterRateFor(application.household).total
            : this.getBaseAmount(application).total;
        
        const subsidy = application.housing.monthlyRent - reductionAmount;

        return correctTotals
            ? (subsidy <= (rabRate?.maximumSubsidy ?? subsidy)
                ? (subsidy >= (rabRate?.minimumSubsidy ?? subsidy)
                        ? subsidy
                        : rabRate?.minimumSubsidy ?? 0
                ) : rabRate?.maximumSubsidy ?? 0)
            : subsidy;
    }

    getBaseAmount(application: Application) {
        const hasNonSupportedIncome = (adult: Person) => !adult.income.includesIncomeSupport && adult.income.totalAnnual > 0;
        const workingParentAmount = application.household.dependants.length && (hasNonSupportedIncome(application.applicant) || application.household.adults.some(hasNonSupportedIncome)) ? 100 : 0;
        
        return {
            total: Math.round((application.incomeTotal / 12 - workingParentAmount) * 0.3),
            workingParentAmount
        };
    }

    getCoreShelterRateFor(household: Household) {
        const coreShelterRate = [...(this.rates?.["CORE SHELTER"] as CoreShelterRateRule[] ?? [])]
            .filter(r => (r.childCount ?? 0) <= household.dependants18OrYounger.length)
            .sort((a, b) => (a.childCount ?? 0) - (b.childCount ?? 0))
            .pop();

        const rate = household.adults.length + 1 > 1
            ? {adultAmount: coreShelterRate?.privateHousingAmounts?.multiAdult ?? 0, childAmount: coreShelterRate?.privateHousingAmounts.extraChildAmountMulti ?? 0}
            : {adultAmount: coreShelterRate?.privateHousingAmounts?.singleAdult ?? 0, childAmount: coreShelterRate?.privateHousingAmounts.extraChildAmountSingle ?? 0};

        const childAmount = rate.childAmount * (household.dependants18OrYounger.length - (coreShelterRate?.childCount ?? 0));

        return {
            rate: coreShelterRate,
            adultAmount: rate.adultAmount,
            extraChildAmount: childAmount,
            total: Math.round(rate.adultAmount + childAmount)
        };
    }
}

class TrabRateCalculator extends RateCalculator {
    amountFor(application: Application): number {
        const trabRateRule = [...(this.rates?.["TRAB"] as TrabRateRule[] ?? [])]
            ?.filter(r => (r.bedrooms ?? 0) <= application.bedrooms)
            .sort((a, b) => (a.bedrooms ?? 0) - (b.bedrooms ?? 0))
            .pop();

        return ((application.type === ApplicationType.Standard ? trabRateRule?.year1Amount : trabRateRule?.year2Amount) ?? 0);
    }
}

class GbvCalculator extends RateCalculator {
    amountFor(application: Application, correctTotals: boolean): number {
        return Math.round(7200 / (application.programDetails.durationInMonths ? application.programDetails.durationInMonths : 12));
    }
}

export {
    RateCalculator,
    RabRateCalculator,
    TrabRateCalculator,
    GbvCalculator
};