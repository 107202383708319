import styles from "styles/application.module.scss";

import { useCallback, useMemo, useState } from "react";

import { Grid, Modal } from "@mui/material";
import { BankingDetails, ContactDetail, EmergencyContacts, GbvCircumstances, HousingDetails, JourneyForm, MemberDetail, ScoreBreakdown, SituationDetail } from "components";
import { OfferDetail } from "components/household/OfferDetail";
import { OfferDialog, StatusDialog } from "components/StatusDialog";
import useAuth from "hooks/useAuth";
import {
	ApplicantInformation,
	Application,
	ApplicationContent,
	ApplicationDocuments,
	ApplicationStatus,
	ApplicationType,
	CommunicationPreference,
	Document,
	DocumentTypeMap,
	halfWidth,
	IDocuments,
	Note,
	OfferApplicationState,
	OfferInfo,
	OfferState,
	PermissionRequiredFor,
	ReadonlyApplicationStatuses
} from "library";
import { useParams } from "react-router-dom";
import { useDispatch } from "store";
import { viewApplicationFinancialInfo } from "store/slices/application";
import { ApproveApplication, ApproveApplicationState, ApprovedAnnualReview, ApprovedApplication, ApprovedInterimReview, ApproveInterimReview, ApproveReview, PaymentInformationRequired, RequestDocuments, SubsidyCalculation } from ".";
import { ApplicationActions } from "./Application.Actions";
import { ApplicationDocumentsForm } from "./Application.Documents";
import { ApplicationHousehold } from "./Application.Household";
import { ApplicationKeyData } from "./Application.KeyData";
import { ApplicationNotes } from "./Application.Notes";
import { ApplicationOffer } from "./Application.Offer";

enum AfterDialogResponses {
	Approved = 1,
	PaymentInfoRequired = 2
}

type SummaryApplicationProps = {
	application: Application | undefined;
	onSave: (value: Partial<Application>, note?: Note) => Promise<void>;
	onRequestDocuments: (value: { application: Application, specialMessage: string, sendEmail: boolean }) => Promise<void>;
	onStatusChange: (value: { status: ApplicationStatus, message: string, notes: string, sendEmail: boolean }) => Promise<void>;
	onApprove: (value: ApproveApplicationState) => Promise<void>;
	onOffer: (value: OfferApplicationState) => Promise<void>;
	onDeleteSupportingDocument: (value: Document) => Promise<void>;
	onAddSupportingDocument: (value: { files: File[], type: keyof IDocuments }) => Promise<void>;
};

const ApplicationForm = ({application: value, onSave, onRequestDocuments, onStatusChange, onApprove, onOffer, onAddSupportingDocument, onDeleteSupportingDocument}: SummaryApplicationProps) => {
	const {id} = useParams();
	const {canEffect} = useAuth();
	const dispatch = useDispatch();
	const [processDialogValue, setShowProcessDialog] = useState<ApplicationStatus | OfferState | AfterDialogResponses>();

	const permissionRequired = useMemo(() => PermissionRequiredFor(value?.type, value?.status), [value]);
	const readonly = useMemo(() => !canEffect(permissionRequired) || !value || id === "new" || value.isOnOffer || ReadonlyApplicationStatuses.includes(value.status), [id, value, canEffect, permissionRequired]);

	const closeProcessDialog = useCallback((_: any = {}, reason: string = "") => {
		if (reason) return;
		setShowProcessDialog(undefined);
	}, [setShowProcessDialog]);

	const onView = useCallback(async () => {
		dispatch(viewApplicationFinancialInfo()).unwrap().then();
	}, [dispatch]);

	const approve = useCallback(async (approval: ApproveApplicationState) => {
		if (!value) return;

		if (!value.documents.bankInfo.canProcess) {
			setShowProcessDialog(AfterDialogResponses.PaymentInfoRequired);
			return;
		}

		await onApprove(approval);
		setShowProcessDialog(AfterDialogResponses.Approved);
	}, [setShowProcessDialog, value, onApprove]);

	const sendOffer = useCallback(async (offer: OfferApplicationState) => {
		if (!value) return;
		await onOffer(offer);
		closeProcessDialog();
	}, [onOffer, value, closeProcessDialog]);
	
	const changeStatus = useCallback((status: ApplicationStatus, message: string, notes: string, sendEmail: boolean) => {
		if (!value) return;
		onStatusChange({status, notes, message, sendEmail})
			.then(() => closeProcessDialog());
	}, [value, onStatusChange, closeProcessDialog]);

	const sendDocumentRequest = useCallback((documents: Partial<IDocuments>, specialMessage: string, sendEmail: boolean) => {
		if (!value) return;

		const documentsRequested = new ApplicationDocuments(documents);
		Object.entries(DocumentTypeMap).map(([k]) => 
			value.documents[k] = {...value.documents[k], required: documentsRequested[k].required}
		);
		const application = new Application({...value, documents: new ApplicationDocuments({...value.documents, specialMessage})});
		onRequestDocuments({application, specialMessage, sendEmail})
			.then(() => closeProcessDialog());
	}, [value, onRequestDocuments, closeProcessDialog]);

	const statusDescription = useCallback((verb: string, followUpText?: string) => (<>
		An email will be sent out to <a href={`mailto: ${value?.applicant.email?.address}`}>{value?.applicant.email?.address ?? ""}</a>{" "}
		notifying the {ApplicationContent[value!.type]!.applicant.toLowerCase()} that their {ApplicationContent[value!.type].heading.toLowerCase()} has been {verb}.
		{followUpText ?? ` Please complete the following details to complete the processing of this ${verb} application.`}
	</>), [value]);

	const statusContent = useCallback((title: string) => (
		<div className={`${styles.modalText} ${styles.fullWidth}`}>
			<span className={styles.bold}>{title}</span>
			<ul>{value?.programs.map(p => <li key={p}>{p}</li>)}</ul>
		</div>
	), [value?.programs]);
	
	const ApproveDialog: Record<ApplicationType, JSX.Element> = useMemo(() => ({
		[ApplicationType.AnnualReview]: <ApproveReview value={value!} onCancel={closeProcessDialog} onApprove={approve} />,
		[ApplicationType.Standard]: <ApproveApplication value={value!} onCancel={closeProcessDialog} onApprove={approve} />,
		[ApplicationType.InterimReview]: <ApproveInterimReview value={value!} onCancel={closeProcessDialog} onApprove={approve} />
	}), [value, closeProcessDialog, approve]);

	const ApprovedDialog : Record<ApplicationType, JSX.Element> = useMemo( () => ({
		[ApplicationType.AnnualReview]: <ApprovedAnnualReview value={value!} onClose={closeProcessDialog} />,
		[ApplicationType.Standard]: <ApprovedApplication value={value!} onClose={closeProcessDialog}/>,
		[ApplicationType.InterimReview]: <ApprovedInterimReview value={value!} onClose={closeProcessDialog}/>
	}), [value, closeProcessDialog]);

	const SubmittedDialog: Record<ApplicationType, JSX.Element> = useMemo(() => (
		{
			[ApplicationType.AnnualReview]: <StatusDialog hideEmail status={ApplicationStatus.Submitted} description={statusContent(`Annual review ${value?.code} will be updated to submitted for the following program(s).`)}
																										communicationPreference={value?.communicationPreference ?? CommunicationPreference.Email}
																										content={""}
																										onCancel={closeProcessDialog} onProceed={changeStatus} />,
			[ApplicationType.InterimReview]: <StatusDialog hideEmail status={ApplicationStatus.Submitted} description={statusContent(`Interim review ${value?.code} will be updated to submitted for the following program(s).`)}
																										 communicationPreference={value?.communicationPreference ?? CommunicationPreference.Email}
																										 content={""} 
																										 onCancel={closeProcessDialog} onProceed={changeStatus}/>,
			[ApplicationType.Standard]: <StatusDialog status={ApplicationStatus.Submitted} description={statusContent("Will be submitted for the following program(s):")} 
																								communicationPreference={value?.communicationPreference ?? CommunicationPreference.Email}
																								content={""}
																								onCancel={closeProcessDialog} onProceed={changeStatus}/>,
		}
	), [changeStatus, closeProcessDialog, statusContent, value?.communicationPreference, value?.code]);

	const NextStatusDialog: Partial<Record<ApplicationStatus | OfferState | AfterDialogResponses, JSX.Element>> | undefined = useMemo(() => value && ({
		[AfterDialogResponses.PaymentInfoRequired]: <PaymentInformationRequired onClose={closeProcessDialog}/>,
		[ApplicationStatus.Submitted]: SubmittedDialog[value.type],
		[ApplicationStatus.Eligible]: <StatusDialog status={ApplicationStatus.Eligible} content={""} communicationPreference={value.communicationPreference}
			description={statusDescription("deemed eligible for rent subsidy but has not been officially approved (activated)", "Their application will wait on a waitlist for final approval (activation)")}
			onCancel={closeProcessDialog} onProceed={changeStatus}/>,
		[OfferState.OnOffer]: <ApplicationOffer value={value} onClose={closeProcessDialog} onSendOffer={sendOffer} />,
		[OfferState.Accepted]: <OfferDialog offerState={OfferState.Accepted} onCancel={closeProcessDialog} onProceed={async () => {await onSave({ isOfferAccepted: true, offerAcceptDeclineDate: new Date()}); closeProcessDialog();} }/>,
		[OfferState.Rescind]: <OfferDialog offerState={OfferState.Rescind} onCancel={closeProcessDialog} onProceed={async () => {await onSave({ onOfferDate: undefined, offerAcceptanceDueDate: undefined, approvedProgram: undefined}); closeProcessDialog();} }/>,
		[ApplicationStatus.Approved]: ApproveDialog[value.type],
		[AfterDialogResponses.Approved]: ApprovedDialog[value.type],
		[ApplicationStatus.WaitingForSupportingDocuments]: <RequestDocuments application={value} communicationPreference={value.communicationPreference} onCancel={closeProcessDialog} onApprove={sendDocumentRequest}/>,
		[ApplicationStatus.Rejected]: <StatusDialog applicationType={value.type} status={ApplicationStatus.Rejected} description={statusDescription("rejected")}
																								communicationPreference={value.communicationPreference}
																								content={statusContent(`${ApplicationContent[value.type].applicant} does not qualify for the following programs:`)} 
																								onCancel={closeProcessDialog} onProceed={changeStatus} />,
		[ApplicationStatus.Cancelled]: <StatusDialog applicationType={value.type} status={ApplicationStatus.Cancelled} description={statusDescription("cancelled")}
																								 communicationPreference={value.communicationPreference}
																								 content={statusContent(`${ApplicationContent[value.type].heading} to be cancelled was for the following program(s):`)} 
																								 onCancel={closeProcessDialog} onProceed={changeStatus}/>,
		[ApplicationStatus.OnHold]: <StatusDialog applicationType={value.type} status={ApplicationStatus.OnHold} description={statusDescription("suspended")}
																							communicationPreference={value.communicationPreference}
																							content={statusContent("Annual Review will be suspended for the following programs:")}
																							onCancel={closeProcessDialog} onProceed={changeStatus}/>
	}), [value, closeProcessDialog, statusDescription, onSave, changeStatus, sendOffer, ApproveDialog, ApprovedDialog, sendDocumentRequest, statusContent, SubmittedDialog]);

	return !value ? <></> : (
		<Grid container>
			<Modal open={!!processDialogValue} onClose={closeProcessDialog}>
				<>{NextStatusDialog![processDialogValue!] ?? <></>}</>
			</Modal>

			{(value.isOnOffer || value.isOfferAccepted) && <OfferDetail value={new OfferInfo(value)} onChange={async v => await onSave({...v})}/>}
			<MemberDetail label={ApplicationContent[value.type].applicant} readonly={readonly && id !== "new"} requireSin value={value.applicant} className={styles.formDetail} editing={id === "new"}
						  onChange={async v => await onSave({applicant: new ApplicantInformation(v as ApplicantInformation)})}/>

			{id !== "new" && <>
				<ContactDetail value={value.contacts} readonly={readonly} onChange={async v => await onSave({contacts: v})}/>
	
				<Grid container className={`${styles.unbordered} ${styles.paddedContainerTopBottom}`}>
						<Grid item {...halfWidth}>
							<JourneyForm title={"Journey"} journey={value.journey}/>
						</Grid>
					
						<Grid item {...halfWidth}>
							<ApplicationKeyData value={value} readonly={readonly} onChange={async (v, note) => await onSave(v, note)}/>
							{(value.isOnOffer || !readonly) && <ApplicationActions application={value} onStatusChange={setShowProcessDialog}/>}
						</Grid>
				</Grid>
	
				{!value.isGbv && <SubsidyCalculation application={value}/>}
				{value.type === ApplicationType.Standard && (<>
					{!value.isGbv && <ScoreBreakdown value={value.pointScore}/>}
					<SituationDetail value={value.situation} readonly={readonly} onChange={v => onSave({situation: v})}/>
				</>)}
				{value.isGbv && <GbvCircumstances value={{circumstances: value.circumstances, programDetails: value.programDetails, incomeSource: value.applicant.income.otherIncome?.[0]?.source}} 
					onChange={v => onSave({ circumstances: v.circumstances, programDetails: v.programDetails, applicant: value.applicant.updateFirstOtherIncomSource(v.incomeSource) })} />}
				<HousingDetails value={value.housing} readonly={readonly} onChange={v => onSave({housing: v})}/>
				{!value.isGbv && <ApplicationHousehold value={value} readonly={readonly} onSave={onSave}/>}
				<ApplicationDocumentsForm value={value} readonly={readonly} onAdd={onAddSupportingDocument} onDelete={onDeleteSupportingDocument}/>
				<BankingDetails value={value.documents.bankInfo} readonly={readonly} onChange={v => onSave({documents: new ApplicationDocuments({...value?.documents, bankInfo: v})})} onView={onView}/>
				<EmergencyContacts readonly={readonly} value={value.contacts} onChange={async v => await onSave({contacts: v})}/>
				<ApplicationNotes value={value.notes} readonly={id !== "new" && ReadonlyApplicationStatuses.includes(value.status)} onChange={v => onSave({notes: v})}/>
			</>}
		</Grid>
	);
};

export {
	ApplicationForm
};
