import { createSlice } from "@reduxjs/toolkit";
import agent from "api/agent";
import { addMonths, Application, ApplicationContent, ApplicationDocuments, ApplicationStatus, DateDisplay, Document, FileInfo, IDocuments, Note, OfferApplicationState } from "library";
import { createAppAsyncThunk } from "./asyncThunk";
import { ApproveApplicationState } from "views";
import { loadRates } from "./rates";
import { getIncomeTypes } from "./incomeTypes";

const createApplication = createAppAsyncThunk("application/create", async (value: Application) => {
	return await agent.Applications.create(value);
});

const getApplication = createAppAsyncThunk("application/get", async (id: string, {getState, dispatch}) => {
	const {
		application: {item},
		applications: {items},
		rates: {rates: loadedRates, loading: ratesNeedLoading},
		incomeTypes: {items: loadedIncomeTypes, loading: incomeTypesNeedLoading}
	} = getState();
	const result = item?.id === id ? item : (items.find(i => i.id === id && !!i.changes) ?? await agent.Applications.get(id));
	if (!result) return undefined;

	const rates = !ratesNeedLoading ? loadedRates : await dispatch(loadRates()).unwrap().then();
	const incomeTypes = !incomeTypesNeedLoading ? loadedIncomeTypes : await dispatch(getIncomeTypes()).unwrap().then();

	return new Application({
		...result,
		journey: await agent.Applications.getJourney(result.id),
	}, rates, incomeTypes);
});

const setApplication = createAppAsyncThunk("application/set", async (value: Partial<Application>, {dispatch, getState}) => {
	const {rates: {rates: loadedRates, loading: ratesNeedLoading}} = getState();
	const rates = !ratesNeedLoading ? loadedRates : await dispatch(loadRates()).unwrap().then();
	
	const currentApplication = value.id && value.code 
		? await dispatch(getApplication(value?.id)).unwrap().then()
		: await agent.Applications.create(value);
	
	if (!currentApplication) throw new Error("Application not found");

	const updatedApplication = new Application({...currentApplication, ...value}, rates);

	const noteFiles = updatedApplication.notes.reduce((a, c) => ([...a, ...c.files.map(f => f.file)]), [] as (File | undefined)[]);
	for (const file of noteFiles.filter(f => !!f))
		await agent.Applications.addFile(updatedApplication.id, file!, "notes");

	return new Application({
		...await agent.Applications.update(updatedApplication),
		journey: await agent.Applications.getJourney(updatedApplication.id)
	}, rates);
});

const approveApplication = createAppAsyncThunk("application/approve", async (value: ApproveApplicationState, {dispatch, getState}) => {
	const {
		application: {item: existing},
		rates: {rates: loadedRates, loading: ratesNeedLoading}
	} = getState();
	
	if (!existing) return;

	const rates = !ratesNeedLoading ? loadedRates : await dispatch(loadRates()).unwrap().then();
	
	return await agent.Applications.approve(new Application({
		...existing,
		clientCode: value.clientCode,
		approvedProgram: {name: value.program!, amount: value.amount!, monthlyAmounts: value.monthlyAmounts, startDate: value.start, endDate: addMonths(value.start, value.programDuration)!},
		notes: [
			...(existing?.notes ?? []),
			new Note({subject: "Approval message", message: value.specialMessage}),
			new Note({subject: `${ApplicationContent[value.type].heading} Approved`, message: value.notes})
		],
		status: ApplicationStatus.Approved
	}, rates), value.specialMessage, value.sendEmail);
});

const offerApplication = createAppAsyncThunk("application/offer", async (value: OfferApplicationState, {dispatch, getState}) => {
	const {
		application: {item: existing},
		rates: {rates: loadedRates, loading: ratesNeedLoading}
	} = getState();
	
	if (!existing) return;

	const rates = !ratesNeedLoading ? loadedRates : await dispatch(loadRates()).unwrap().then();
	
	const updatedApplication = await agent.Applications.offer(new Application({
		...existing,
		onOfferDate: new Date(),
		offerAcceptanceDueDate: value.offerAcceptanceDueDate,
		approvedProgram: {name: value.program!, amount: value.amount!, monthlyAmounts: value.monthlyAmounts, startDate: value.start, endDate: addMonths(value.start, value.programDuration)!},
		notes: [
			...(existing?.notes ?? []),
			new Note({subject: "Application Offer message", message: value.specialMessage}),
			new Note({subject: "Application Offer", message: value.notes}),
			new Note({subject: "Offer Made", message: `An offer to ${value.program} has been made with an acceptance due date of ${DateDisplay.Standard(value.offerAcceptanceDueDate)}`}),
		]
	}, rates), value.specialMessage, value.sendEmail);
	
	return new Application({
		...updatedApplication,
		journey: await agent.Applications.getJourney(updatedApplication.id)
	}, rates);
});

const applicationStatusNoteMapping: Partial<Record<ApplicationStatus, (value: { message: string, notes: string, typeName: string }) => Note[]>> = {
	[ApplicationStatus.Submitted]: a => [new Note({message: a.message, subject: "Submitted Reason"})],
	[ApplicationStatus.Rejected]: a => [new Note({message: a.message, subject: "Rejected Reason"}), new Note({message: a.notes, subject: `${a.typeName} Rejected`})],
	[ApplicationStatus.Cancelled]: a => [new Note({message: a.message, subject: "Cancelled Reason"}), new Note({message: a.notes, subject: `${a.typeName} Cancelled`})],
	[ApplicationStatus.OnHold]: a => [new Note({message: a.message, subject: "Suspended Reason"}), new Note({message: a.notes, subject: "Annual Review Suspended"})],
	[ApplicationStatus.Eligible]: a => [new Note({message: a.message, subject: "Eligible Reason"}), new Note({message: a.notes, subject: "Application Eligible"})]
};

const changeApplicationStatus = createAppAsyncThunk("application/changeStatus", async (value: { status: ApplicationStatus, message: string, notes: string, sendEmail: boolean }, {dispatch, getState}) => {
	const {
		application: {item: existing},
		rates: {rates: loadedRates, loading: ratesNeedLoading}
	} = getState();
	
	if (!existing) return;

	const rates = !ratesNeedLoading ? loadedRates : await dispatch(loadRates()).unwrap().then();
	
	return new Application({
		...await agent.Applications.changeStatus(new Application({
			...existing,
			notes: [
				...(existing?.notes ?? []),
				...applicationStatusNoteMapping[value.status]!({message: value.message, notes: value.notes, typeName: ApplicationContent[existing.type].heading})
			],
			status: value.status
		}), value.message, value.sendEmail),
		journey: await agent.Applications.getJourney(existing.id)
	}, rates);
});

const requestDocuments = createAppAsyncThunk("application/requestDocuments", async (value: { application: Application, specialMessage: string, sendEmail: boolean }, {dispatch, getState}) => {
	const {rates: {rates: loadedRates, loading: ratesNeedLoading}} = getState();
	const rates = !ratesNeedLoading ? loadedRates : await dispatch(loadRates()).unwrap().then();
	
	return new Application({
		...await agent.Applications.update(new Application({...value.application, status: ApplicationStatus.WaitingForSupportingDocuments}, rates), value.specialMessage, value.sendEmail),
		journey: await agent.Applications.getJourney(value.application.id)
	}, rates);
});

const viewApplicationFinancialInfo = createAppAsyncThunk("application/financialInfo", async (_, {getState}) => {
    const {application: {item: application}} = getState();
    await agent.Applications.getFinancialInfo(application!.id);
});

const viewOrDownloadApplicationDocument = createAppAsyncThunk("application/viewOrDownloadDocument", async (value: { fileName: string, documentType: string }, {getState}) => {
	const {application: {item: application}} = getState();
	return await agent.Applications.getFile(application!.id, value.fileName, value.documentType);
});

const deleteApplicationDocument = createAppAsyncThunk("application/deleteDocument", async (value: Document, {dispatch, getState}) => {
	const {
		application: {item: application},
		rates: {rates: loadedRates, loading: ratesNeedLoading}
	} = getState();

	const rates = !ratesNeedLoading ? loadedRates : await dispatch(loadRates()).unwrap().then();
	
	return await agent.Applications.update(new Application({
		...application!,
		documents: new ApplicationDocuments({
			...application!.documents,
			...{[value.documentType]: {required: true, files: application!.documents[value.documentType].files.filter(f => !f.equivalentTo(value.file))}}
		})
	}, rates));
});

const addDocuments = createAppAsyncThunk("application/addDocuments", async (value: { files: File[], type: keyof IDocuments }, {dispatch, getState}) => {
	const {
		application: {item: application},
		rates: {rates: loadedRates, loading: ratesNeedLoading}
	} = getState();

	const rates = !ratesNeedLoading ? loadedRates : await dispatch(loadRates()).unwrap().then();

	for (const file of value.files)
		await agent.Applications.addFile(application!.id, file, value.type);

	return await agent.Applications.update(new Application({
		...application!,
		documents: new ApplicationDocuments({
			...application!.documents,
			...{[value.type]: {required: true, files: [...(application!.documents[value.type]?.files ?? []), ...value.files.map(f => new FileInfo({name: f.name, type: f.type, size: f.size}))]}}
		})
	}, rates));
});

const initialState: { loading: boolean, processing: boolean, item?: Application } = {
	loading: false,
	processing: false,
};

const application = createSlice({
	name: "application",
	initialState,
	reducers: {
	},
	extraReducers: builder => {
		builder.addCase(createApplication.pending, (state) => ({...state, loading: true, item: undefined}));
		builder.addCase(createApplication.fulfilled, (state, {payload}) => ({...state, loading: false, item: payload}));
		builder.addCase(getApplication.pending, (state) => ({...state, loading: true, item: undefined}));
		builder.addCase(getApplication.fulfilled, (state, {payload}) => ({...state, loading: false, processing: false, item: payload}));
		builder.addCase(offerApplication.pending, (state) => ({...state, loading: false, processing: true}));
		builder.addCase(offerApplication.fulfilled, (state, {payload}) => ({...state, loading: false, processing: false, item: payload}));
		builder.addCase(approveApplication.pending, (state) => ({...state, loading: false, processing: true}));
		builder.addCase(approveApplication.fulfilled, (state, {payload}) => ({...state, loading: false, processing: false, item: payload}));
		builder.addCase(changeApplicationStatus.pending, (state) => ({...state, loading: false, processing: true}));
		builder.addCase(changeApplicationStatus.fulfilled, (state, {payload}) => ({...state, loading: false, processing: false, item: payload}));
		builder.addCase(setApplication.pending, (state) => ({...state, processing: true}));
		builder.addCase(setApplication.fulfilled, (state, {payload}) => ({...state, loading: false, processing: false, item: payload}));
		builder.addCase(addDocuments.pending, (state) => ({...state, processing: true}));
		builder.addCase(addDocuments.fulfilled, (state, {payload}) => ({...state, item: payload}));
		builder.addCase(requestDocuments.pending, (state) => ({...state, loading: false, processing: true}));
		builder.addCase(requestDocuments.fulfilled, (state, {payload}) => ({...state, loading: false, processing: false, item: payload}));
		builder.addCase(deleteApplicationDocument.pending, (state) => ({...state, loading: true, processing: true,}));
		builder.addCase(deleteApplicationDocument.fulfilled, (state, {payload}) => ({...state, loading: false, processing: false, item: payload}));
	}
});

export default application.reducer;

export {createApplication, getApplication, setApplication, approveApplication, offerApplication, changeApplicationStatus, requestDocuments, viewApplicationFinancialInfo, viewOrDownloadApplicationDocument, deleteApplicationDocument, addDocuments};