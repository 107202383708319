import styles from "styles/communicationPreference.module.scss";

import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { ThreeDots } from "react-loader-spinner";
import { CommunicationPreference } from "library";
import { MenuItem, Select } from "@mui/material";

interface ICommunicationPreferenceSelectProps {
	readonly: boolean;
	communicationPreference?: CommunicationPreference;
	onSave: (communicationPreference?: CommunicationPreference) => Promise<void>;
}

const CommunicationPreferenceSelect = ({ readonly, communicationPreference, onSave }: ICommunicationPreferenceSelectProps) => {
  const [processing, setProcessing] = useState(false);
  const theme = useTheme();
  const size = "38px";

  const onChange = async (communicationPreference?: CommunicationPreference) => {
    if (!onSave) return;

    setProcessing(true);
    await onSave(communicationPreference).finally(() => setProcessing(false));
  };

  return (
    processing
      ? <span><ThreeDots width={size} height={size} color={theme.palette.primary.main} /></span>
      : <>
        <span className={styles.communicationPreference}>Communication Preference</span>
        {!readonly 
          ? <Select value={communicationPreference} variant={"standard"} onChange={v => onChange(v.target.value as CommunicationPreference)}>
              {Object.entries(CommunicationPreference).map(([k, v]) => <MenuItem key={k} value={v}>{v}</MenuItem>)}
            </Select>
          : <span>{communicationPreference}</span>}
      </>
  );
};

export { CommunicationPreferenceSelect }