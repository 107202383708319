import styles from "styles/settings.module.scss";

import {Edit, PpulusLoader} from "components";
import React, {useCallback, useEffect, useState} from "react";
import { Button, Checkbox, FormControlLabel, Switch, Typography } from "@mui/material";
import {IconCheck} from "@tabler/icons";
import {useDispatch, useSelector} from "store";
import {loadSettings, saveSettings} from "store/slices/settings";
import {GeneralSettings, PaymentFileSettings} from "library";
import { FeatureFlag } from "../../types/featureFlag";

const SettingsForm = () => {
	const dispatch = useDispatch();
	const {settings, loading} = useSelector(s => s.settings);
	const [processing, setProcessing] = useState(false);
	const [state, setState] = useState(settings);

	useEffect(() => {
		dispatch(loadSettings())
			.unwrap()
			.then(setState);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const set = useCallback((value: Partial<GeneralSettings>) => setState(new GeneralSettings({...state, ...value})), [state, setState]);
	const setPayment = useCallback((value: Partial<PaymentFileSettings>) => set({...state, paymentSettings: {...state.paymentSettings, ...value}}), [set, state]);

	const save = useCallback(() => {
		setProcessing(true);

		dispatch(saveSettings(state))
			.unwrap()
			.then(setState)
			.finally(() => setProcessing(false));
	}, [state, dispatch]);

	if (loading)
		return <PpulusLoader/>;

	return (
		<div className={styles.content}>
			<Typography variant={"h2"}>Settings</Typography>

			<div className={styles.grouping}>
				<Typography variant={"h4"}>Payment Settings</Typography>

				<div className={styles.row}>
					<div className={styles.span2}>
						<Edit label={"Routing Header"} value={state.paymentSettings.routingHeader} disabled={processing} onChange={v => setPayment({routingHeader: v})}/>
					</div>
				</div>
                
				<div className={styles.row}>
					<Edit label={"Client Number"} value={state.paymentSettings.clientNumber} disabled={processing} onChange={v => setPayment({clientNumber: v})}/>
					<Edit label={`${state.paymentSettings.sequentialFileNumber ? "Next " : ""}File Number`} disabled={processing}
						value={state.paymentSettings.sequentialFileNumber ? Number(state.paymentSettings.fileNumber) : state.paymentSettings.fileNumber}
						onChange={v => setPayment({fileNumber: `${v}`})}
						onBlur={v => setPayment({fileNumber: `${v}`.padStart(4, "0")})}
					/>

					<FormControlLabel label={"Use Sequential File Number"}
						labelPlacement={"end"}
						checked={state.paymentSettings.sequentialFileNumber}
						disabled={processing}
						control={<Checkbox onChange={() => setPayment({sequentialFileNumber: !state.paymentSettings.sequentialFileNumber})}/>}
					/>
				</div>
			</div>
			<div className={styles.grouping}>
				<Typography variant={"h4"}>On Offer Settings</Typography>

				<div className={styles.row}>
					<Edit label={"On Offer Number Of Days"} type={"number"} value={state.onOfferNumberOfDays ?? 7} disabled={processing} onChange={v => set({onOfferNumberOfDays: v})}/>
				</div>
			</div>

			<div className={styles.grouping}>
				<Typography variant={"h4"}>Feature Flags</Typography>
				{state.featureFlags?.map((featureFlag: FeatureFlag) => (
					<div className={styles.featureFlag} key={featureFlag.id}>
						<Typography>{featureFlag.description}</Typography>
						<Switch value={featureFlag.isEnabled} checked={featureFlag.isEnabled} onChange={(_,checked) =>
							set({...state.featureFlags, featureFlags: [{id: featureFlag.id, description: featureFlag.description, isEnabled: checked}]})}/>
					</div>
				))}
			</div>
			<div className={styles.buttonGroup}>
				<Button variant={"contained"} className={styles.button} disabled={processing} color={"primary"} onClick={save}><IconCheck className={styles.iconButton}/>Save</Button>
			</div>

		</div>
	);
};

export {
	SettingsForm
};