import styles from "styles/application.module.scss";

import {BankInfo, BooleanDisplay, fullWidth, oneThirdWidth, quarterWidth, YesNo} from "library";
import React, {ReactNode, useEffect, useState} from "react";
import {FormControlLabel, Grid, TextField, Typography} from "@mui/material";
import {Actions} from "./Actions";
import {FormValueDisplay} from "../FormValueDisplay";
import {YesNoButton} from "../YesNoButton";

interface IBankingDetailsProps {
    value: BankInfo;
    readonly?: boolean;
    onChange: (value: BankInfo) => Promise<void>;
    onView: () => Promise<void>;
}

const BankingDetails: (props: IBankingDetailsProps) => ReactNode = ({value, readonly, onChange, onView}) => {
    const [processing, setProcessing] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [visible, setVisibility] = useState(false);
    const [editValue, setEditValue] = useState(value);

    useEffect(() => {
        setEditValue(value);
    }, [value]);
    
    const formatText = (text: string | undefined, endingDigitsToShow: number = 0) => {
        if (value.chequePayments) return "Not required";
        if (!text) return "Not supplied";

        const digitsToReplace = (!endingDigitsToShow ? text : text?.slice(0, -endingDigitsToShow)) ?? "";
        const digitsToKeep = !endingDigitsToShow ? "" : text?.slice(-endingDigitsToShow);

        return `${visible ? digitsToReplace : digitsToReplace?.replaceAll(/./g, "#")}${digitsToKeep}`;
    };

    const inError = (text: string) => {
        return editMode
            ? !text && !editValue.chequePayments
            : !text && !value.chequePayments;
    };

    const onPaymentDetailsToggleView = (show: boolean) => {
        if (show) void onView();
        setVisibility(show);
    };

    const set = (newValue: Partial<BankInfo>) => setEditValue(new BankInfo({...editValue, ...newValue}));

    const onCancel = () => {
        setEditValue(value);
        setEditMode(false);
    };

    const onSave = async () => {
        if (!onChange || !editValue) return;

        setProcessing(true);
        onChange(editValue)
            .then(() => setEditMode(false))
            .finally(() => setProcessing(false));
    };

    return (
        <Grid container className={styles.card}>
            <Grid item {...fullWidth} className={styles.title}>
                <Typography variant={"h3"} className={styles.contactLabel}>
                    Payment Details {!readonly && <Actions processing={processing}
                                                           editMode={editMode} onEdit={() => setEditMode(true)}
                                                           showVisibility onToggleView={show => onPaymentDetailsToggleView(show)}
                                                           onCancel={onCancel} onSave={onSave}/>}
                </Typography>
            </Grid>

            <Grid item {...fullWidth}>
                <Grid container>
                    <Grid item {...oneThirdWidth} className={styles.column}>
                        <FormValueDisplay {...oneThirdWidth} label={"Cheque Payments"}
                                          value={!editMode
                                              ? BooleanDisplay(value?.chequePayments ?? false)
                                              : <YesNoButton disabled={processing}
                                                             value={editValue?.chequePayments ? YesNo.Yes : YesNo.No}
                                                             onChange={v => setEditValue(new BankInfo({...editValue, chequePayments: v === YesNo.Yes}))}/>}
                        />
                    </Grid>
                    <Grid item {...oneThirdWidth} className={styles.column}>
                        <FormValueDisplay {...oneThirdWidth} label={"Organization?"}
                                          value={!editMode
                                              ? BooleanDisplay(value?.isOrganization ?? false)
                                              : <YesNoButton disabled={processing}
                                                             value={editValue?.isOrganization ? YesNo.Yes : YesNo.No}
                                                             onChange={v => setEditValue(new BankInfo({...editValue, isOrganization: v === YesNo.Yes}))}/>}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className={`${styles.rowTopPadding} ${styles.alignBottom}`}>
                <Grid item {...quarterWidth} className={styles.column}>
                    <FormControlLabel label={"Bank Account Holder's Legal Name"} labelPlacement={"top"}
                                      classes={{label: styles.bold}}
                                      control={!editMode
                                          ? <span className={!inError(value.holderName) ? "" : styles.error}>{formatText(value.holderName, 100)}</span>
                                          : !editValue.chequePayments
                                              ? <TextField value={editValue.holderName} disabled={processing} fullWidth onChange={v => set({holderName: v.target.value})}/>
                                              : <span className={styles.bold}>Not Required</span>}
                    />
                </Grid>

                <Grid item {...quarterWidth} className={styles.column}>
                    <FormControlLabel label={"Institution Number"} labelPlacement={"top"}
                                      classes={{label: styles.bold}}
                                      control={!editMode
                                          ? <span className={!inError(value.institution) ? "" : styles.error}>{formatText(value.institution)}</span>
                                          : !editValue.chequePayments
                                              ? <TextField type={"number"} value={editValue.institution} disabled={processing} inputProps={{maxLength: 3}} fullWidth onChange={(v) => set({institution: v.target.value})}/>
                                              : <span className={styles.bold}>Not Required</span>}
                    />
                </Grid>
                
                <Grid item {...quarterWidth} className={styles.column}>
                    <FormControlLabel label={"Transit Number"} labelPlacement={"top"}
                                      classes={{label: styles.bold}}
                                      control={!editMode
                                          ? <span className={!inError(value.transit) ? "" : styles.error}>{formatText(value.transit)}</span>
                                          : !editValue.chequePayments
                                              ? <TextField type={"number"} value={editValue.transit} disabled={processing} inputProps={{maxLength: 5}} fullWidth onChange={(v) => set({transit: v.target.value})}/>
                                              : <span className={styles.bold}>Not Required</span>}
                    />
                </Grid>

                <Grid item {...quarterWidth} className={styles.column}>
                    <FormControlLabel label={"Account Number"} labelPlacement={"top"}
                                      classes={{label: styles.bold}}
                                      control={!editMode
                                          ? <span className={!inError(value.account) ? "" : styles.error}>{formatText(value.account, 4)}</span>
                                          : !editValue.chequePayments
                                              ? <TextField type={"number"} value={editValue.account} disabled={processing} inputProps={{maxLength: 12}} fullWidth onChange={(v) => set({account: v.target.value})}/>
                                              : <span className={styles.bold}>Not Required</span>}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export {
    BankingDetails
}