import styles from "styles/application.module.scss";

import { Autocomplete, FormControl, Grid, Input, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Edit, FormValueDisplay, YesNoButton } from "components";
import { Circumstances, fromYesNo, fullWidth, halfWidth, hasError, HousingType, ICircumstances, IncomeType, IProgramDetails, oneThirdWidth, ProgramDetails, ProgramDuration, toYesNo, twoThirdWidth, YesNo } from "library";
import { ReactNode, useEffect, useState } from "react";
import { useSelector } from "store";
import { Actions } from "./Actions";

interface IGbvDetails {
	circumstances: Circumstances;
	programDetails: ProgramDetails;
	incomeSource?: IncomeType;
}

interface IGbvCircumstancesProps {
	value: IGbvDetails;
	readonly?: boolean;
	onChange: (value: IGbvDetails) => Promise<void>;
}

const GbvCircumstances: (props: IGbvCircumstancesProps) => ReactNode = ({ value, readonly, onChange }) => {
	const [processing, setProcessing] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [editValue, setEditValue] = useState(value);
    const {items: incomeTypes} = useSelector(s => s.incomeTypes)

	useEffect(() => {
		setEditValue(value);
	}, [value]);

	const setCircumstances = (value: Partial<ICircumstances>) => {
		setEditValue({ ...editValue, circumstances: new Circumstances({...editValue.circumstances, ...value}) });
	}

	const setProgramDetails = (value: Partial<IProgramDetails>) => {
		setEditValue({ ...editValue, programDetails: new ProgramDetails({...editValue.programDetails, ...value}) });
	}

	const onCancel = () => {
		setEditValue(value);
		setEditMode(false);
	};

	const onSave = async () => {
		if (!onChange) return;

		const programDetails = editValue.programDetails.validate();
        setEditValue({...editValue, programDetails});
        if (hasError(programDetails)) return;

		setProcessing(true);
		onChange(editValue)
			.then(() => setEditMode(false))
			.finally(() => setProcessing(false));
	};

	return (
		<Grid container className={`${styles.content} ${styles.card}`}>
			<Grid item {...fullWidth}>
				<Typography variant={"h3"} className={styles.title}>
					GBV Details {!readonly && <Actions processing={processing} editMode={editMode} onEdit={() => setEditMode(true)} onSave={onSave} onCancel={onCancel} />}
				</Typography>
			</Grid>
			<Grid item {...halfWidth} className={styles.column}>
				<FormValueDisplay label={"Primary Income Source"} labelWidth={oneThirdWidth} value={!editMode
					? editValue.incomeSource?.name
					: <Autocomplete value={editValue.incomeSource} disabled={processing} fullWidth
						renderInput={p => <TextField {...p} />}
						getOptionLabel={p => p.name}
						options={incomeTypes} onChange={(_, v) => setEditValue({...editValue, incomeSource: v ?? editValue.incomeSource}) } />} />
				<FormValueDisplay label={"Housing Type"} labelWidth={oneThirdWidth} value={!editMode
					? editValue.circumstances.housingType
					: <Select fullWidth value={editValue.circumstances.housingType} variant={"standard"} disabled={processing} onChange={v => setCircumstances({ housingType: v.target.value as HousingType })}>
						{Object.entries(HousingType).map(([k, v]) => <MenuItem key={k} value={v}>{v}</MenuItem>)}
					</Select>} />
			</Grid>
			<Grid item {...fullWidth} mt={2} mb={2}>
				<Typography variant={"h4"}>GBV Circumstances</Typography>
			</Grid>
			<Grid item {...oneThirdWidth} className={styles.column}>
				<FormValueDisplay label={"Violence (last 6 months)"} value={!editMode
					? editValue.circumstances.experiencedRecently
					: <YesNoButton value={editValue.circumstances.experiencedRecently} disabled={processing} onChange={v => setCircumstances({ experiencedRecently: v })} />} />
			</Grid>
			<Grid item {...twoThirdWidth} className={styles.column}>
				<FormValueDisplay label={"Violence (no explanation)"} labelWidth={oneThirdWidth} value={!editMode
					? editValue.circumstances.reasonForBenefit
					: <Input type={"text"} fullWidth
						value={editValue.circumstances.reasonForBenefit}
						disabled={processing}
						onChange={v => setCircumstances({ reasonForBenefit: v.target.value })} />} />
			</Grid>
			<Grid item {...oneThirdWidth} className={styles.column}>
				<FormValueDisplay label={"Still Threat of GBV?"} value={!editMode
					? editValue.circumstances.underThreat
					: <YesNoButton value={toYesNo(editValue.circumstances.underThreat)} disabled={processing} onChange={v => setCircumstances({ underThreat: fromYesNo(v) })} />} />
				<FormValueDisplay label={"Live in Community Housing?"} value={!editMode
					? editValue.circumstances.communityHousing
					: <YesNoButton value={toYesNo(editValue.circumstances.communityHousing)} disabled={processing} onChange={v => setCircumstances({ communityHousing: fromYesNo(v) })} />} />
			</Grid>
			<Grid item {...oneThirdWidth} className={styles.column}>
				<FormValueDisplay label={"Safe Spaces Certificate?"} value={!editMode
					? editValue.circumstances.safeSpace
					: <YesNoButton value={toYesNo(editValue.circumstances.safeSpace)} disabled={processing} onChange={v => setCircumstances({ safeSpace: fromYesNo(v) })} />} />
				<FormValueDisplay label={"Long Term Housing Plans?"} value={!editMode
					? editValue.circumstances.longTermHousingPlan
					: <YesNoButton value={toYesNo(editValue.circumstances.longTermHousingPlan)} disabled={processing} onChange={v => setCircumstances({ longTermHousingPlan: fromYesNo(v) })} />} />
			</Grid>
			<Grid item {...oneThirdWidth} className={styles.column}>
				<FormValueDisplay label={"Receiving Housing Subsidy?"} value={!editMode
					? editValue.circumstances.subsidized
					: <YesNoButton value={toYesNo(editValue.circumstances.subsidized)} disabled={processing} onChange={v => setCircumstances({ subsidized: fromYesNo(v) })} />} />
			</Grid>
			<Grid item {...fullWidth} mt={2} mb={2}>
				<Typography variant={"h4"}>Referring Agency Details</Typography>
			</Grid>
			<Grid item {...oneThirdWidth} className={styles.column}>
				<FormValueDisplay label={"Agency Referral?"} value={!editMode
					? editValue.programDetails.haveReferral ? YesNo.Yes : YesNo.No
					: <YesNoButton value={editValue.programDetails.haveReferral? YesNo.Yes : YesNo.No} disabled={processing} onChange={v => setProgramDetails({ haveReferral: v === YesNo.Yes })} />} />
			</Grid>
			<Grid item {...twoThirdWidth} className={styles.column}>
			</Grid>
			{editValue.programDetails.haveReferral && <>
				<Grid item {...oneThirdWidth} className={styles.column}>
					<FormValueDisplay label={"Referring Agent Name"} value={!editMode
						? editValue.programDetails.referralName
						: <Edit value={editValue.programDetails.referralName} disabled={processing} error={editValue.programDetails.errorState.referralName} onChange={v => setProgramDetails({ referralName: v })} />} />
				</Grid>
				<Grid item {...oneThirdWidth} className={styles.column}>
					<FormValueDisplay label={"Agent Phone Number"} value={!editMode
						? editValue.programDetails.referralPhone
						: <Edit value={editValue.programDetails.referralPhone} disabled={processing} error={editValue.programDetails.errorState.referralPhone} onChange={v => setProgramDetails({ referralPhone: v })} />} />
				</Grid>
				<Grid item {...oneThirdWidth} className={styles.column}>
					<FormValueDisplay label={"Agent Email Address"} value={!editMode
						? editValue.programDetails.referralEmail
						: <Edit value={editValue.programDetails.referralEmail} disabled={processing} error={editValue.programDetails.errorState.referralEmail} onChange={v => setProgramDetails({ referralEmail: v })} />} />
				</Grid>
				<Grid item {...oneThirdWidth} className={styles.column}>
					<FormValueDisplay label={"Agent Occupation Role"} value={!editMode
						? editValue.programDetails.referralRole
						: <Input type={"text"} fullWidth
							value={editValue.programDetails.referralRole}
							disabled={processing}
							onChange={v => setProgramDetails({ referralRole: v.target.value })} />} />
				</Grid>
			</>}
			<Grid item {...fullWidth} mt={2} mb={2}>
				<Typography variant={"h4"}>Program Duration</Typography>
			</Grid>
			<Grid item {...oneThirdWidth} className={styles.column}>
				<FormValueDisplay label={"Preferred Duration"} value={!editMode
					? editValue.programDetails.duration
					: <FormControl error={!!editValue.programDetails.errorState.duration} fullWidth>
						<Select fullWidth value={editValue.programDetails.duration} variant={"standard"} disabled={processing} onChange={v => setProgramDetails({ duration: v.target.value as ProgramDuration })}>
							{Object.entries(ProgramDuration).map(([k, v]) => <MenuItem key={k} value={v}>{v}</MenuItem>)}
						</Select></FormControl>} />
			</Grid>
			<Grid item {...oneThirdWidth} className={styles.column}>
				<FormValueDisplay label={"Other Duration"} value={`${editValue.programDetails.durationInMonths ?? ""}`} />
			</Grid>
		</Grid>
	);
}

export {
	GbvCircumstances
};
