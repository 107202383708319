export function getCookie(key: string) {
	const b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
	return b ? b.pop() : "";
}
export function compareTwoArrays(arr1: any, arr2: any) {
	if (arr1.length !== arr2.length)
		return false;

	// Then, compare each element
	for (let i = 0; i < arr1.length; i++)
		if (arr1[i] !== arr2[i]) return false;

	return true;
}

function deepEqual(a: any, b: any): boolean {
	if (a === b) return true; // Primitive values and references that are strictly equal

	if (typeof a !== "object" || typeof b !== "object" || a === null || b === null)
		return false; // One is object, one is not, or one is null, or different types

	if (Array.isArray(a) && Array.isArray(b)) {
		if (a.length !== b.length) return false;
		return a.every((el, index) => deepEqual(el, b[index])); // Recursively compare each element
	}

	const keysA = Object.keys(a);
	const keysB = Object.keys(b);

	if (keysA.length !== keysB.length) return false;

	return keysA.every(key => deepEqual(a[key], b[key])); // Recursively compare each property
}

export function deepArraysAreEqual(arr1: any[], arr2: any[]): boolean {
	if (arr1.length !== arr2.length) return false;

	return arr1.every((el, index) => deepEqual(el, arr2[index])); // Use deepEqual on each element
}

export function currencyFormat(amount: number) {
	return "$" + (amount / 100).toFixed(2);
}

export function formatDate(date?: Date | string): string {
	if (typeof date === "undefined") {
		return "";
	}

	const dateToFormat = (typeof date === "string") ? new Date(date) : date;

	return dateToFormat.toLocaleDateString("en-US", { month: "short", day: "2-digit", year: "numeric" }).replace(/(\d{1,2})(st|nd|rd|th)/, "$1");
}

export type Grouped<T> = { [key: string]: T[] };

export const groupBy = <T, K extends keyof T>(array: T[], keySelector: K): Grouped<T> =>
	array.reduce((result, item) => {
		const key = String(item[keySelector]);
		if (!result[key]) result[key] = [];
		
		result[key].push(item);
		return result;
	}, {} as Grouped<T>);

export interface SortOptions<T> {
	key?: keyof T;
	compareFn?: (left: T, right: T) => number;
	ascending?: boolean;
}

export function sortBy<T>(array: T[], options?: SortOptions<T>): T[] {
	if (!array?.length)
		return [];

	const {key, compareFn, ascending = true } = options || {};
	const sortOrder = ascending ? 1 : -1;

	function defaultCompare(left: T, right: T) {
		if (left === right)
			return 0;

		const leftValue = key ? left[key] : left;
		const rightValue = key ? right[key] : right;

		if (typeof leftValue === "string" && typeof rightValue === "string")
			return leftValue.localeCompare(rightValue);

		if (typeof leftValue === "boolean" && typeof rightValue === "boolean")
			return leftValue ? 1 : -1;
		return leftValue > rightValue ? 1 : -1;
	}

	return [...array].sort((left, right) => (compareFn ?? defaultCompare)(left,right) * sortOrder);
}
