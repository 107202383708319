import styles from "styles/application.module.scss";

import {fromYesNo, fullWidth, ISituation, oneThirdWidth, Situation, SituationCategories, SituationCategory, toYesNo, YesNoSilent} from "library";
import React, {ReactNode, useEffect, useState} from "react";
import {Box, Grid, Popper, Typography, useTheme} from "@mui/material";
import {IconInfoCircle} from "@tabler/icons";
import {FormValueDisplay} from "../FormValueDisplay";
import {YesNoButton} from "../YesNoButton";
import {Actions} from "./Actions";

interface ISituationDetailProps {
	value: Situation;
	readonly?: boolean;
	onChange: (value: Situation) => Promise<void>;
}

const SituationDetail: (props: ISituationDetailProps) => ReactNode = ({value, readonly, onChange}) => {
	const [processing, setProcessing] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [editValue, setEditValue] = useState(value);

	useEffect(() => {
		setEditValue(value);
	}, [value]);
	
	const set = (value: Partial<Situation>) => setEditValue(new Situation({...editValue, ...value}));

	const onCancel = () => {
		setEditValue(value);
		setEditMode(false);
	};

	const onSave = async () => {
		if (!onChange) return;
		
		setProcessing(true);
		onChange(editValue)
			.then(() => setEditMode(false))
			.finally(() => setProcessing(false));
	};
	
	return (
		<Grid container className={styles.card}>
			<Grid item {...fullWidth}>
				<Typography variant={"h3"} className={styles.title}>
					Circumstances {!readonly && <Actions processing={processing} editMode={editMode} onEdit={() => setEditMode(true)} onSave={onSave} onCancel={onCancel}/>}
				</Typography>
			</Grid>
			<Grid container>
				{SituationCategories.map(c => <SituationGrouping key={c} category={c} {...{editMode, editValue, processing}} onSet={set}
																 values={Object.entries(value).filter(([, v]) => typeof v === "string").filter(([k, v]) => Situation.map(k as keyof ISituation).category === c)}/>)}
			</Grid>
		</Grid>
	);
};

type SituationGroupingProps = {
	category: SituationCategory;
	editMode: boolean;
	editValue: Situation;
	processing: boolean;
	values: [string, YesNoSilent][];
	onSet: (value: Partial<Situation>) => void;
};

const SituationGrouping = ({category, values, editValue, editMode, processing, onSet}: SituationGroupingProps) => {
	const theme = useTheme();
	const [hintText, setHintText] = useState("");
	const [textAnchor, setTextAnchor] = useState<SVGElement | null>();

	const toggleHintText = (event: React.MouseEvent<SVGElement>, text?: string) => {
		setTextAnchor(event.currentTarget);
		setHintText(text ?? "");
	};

	return (
		<Grid container mb={3}>
			<Grid item {...fullWidth} mb={2}>
				<Typography variant={"h4"}>{category}</Typography>
			</Grid>
			<Popper open={!!hintText} placement={"auto-end"} anchorEl={textAnchor}>
				<Box sx={{border: 1, p: 1, bgcolor: theme.palette.primary.main, color: theme.palette.text.primary, borderRadius: "4px", maxWidth: "300px"}}>
					{hintText}
				</Box>
			</Popper>
			{values.map(([k, v]) => (
				<Grid key={k} item {...oneThirdWidth} className={styles.column}>
					{!editMode
						? <FormValueDisplay
							label={(
								<>
									<IconInfoCircle onMouseOver={e => toggleHintText(e, Situation.map(k as keyof ISituation)?.description)} onMouseOut={toggleHintText}/>
									{Situation.map(k as keyof ISituation)?.label}
								</>
							)} value={v}
						/>
						: <FormValueDisplay label={(
							<>
								<IconInfoCircle onMouseOver={e => toggleHintText(e, Situation.map(k as keyof ISituation)?.description)} onMouseOut={toggleHintText}/>
								{Situation.map(k as keyof ISituation)?.label}
							</>
						)} value={<YesNoButton value={toYesNo(editValue[k])} disabled={processing} onChange={v => onSet({[k]: fromYesNo(v)})} optional/>}/>
					}
				</Grid>
			))}
		</Grid>
	);
}

export {
	SituationDetail
};