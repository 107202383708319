import approveStyles from "styles/applicationApprove.module.scss";
import styles from "styles/application.module.scss";

import { addMonths, OfferApplicationState } from "library";
import { Grid, TextField } from "@mui/material";
import { useMemo } from "react";

type ApplicationDisbursementsProps = {
  state: OfferApplicationState;
  onChange?: (value: number[]) => void;
}

const ApplicationDisbursements = ({state, onChange}: ApplicationDisbursementsProps) => {
  const distributed = useMemo(() => state.amount - state.unallocated, [state]);
  
  if (!state.isGbv) return <></>;

  return (
    <Grid container className={styles.modalText}>
      <div>
        <div className={approveStyles.header}>Disbursements</div>
        <p>
          By default, Ppulus will take the maximum benefit amount and will divide it into equal payments for the applicant's preferred duration with the 1st disbursement scheduled for the period of the Program Start Date.
        </p>
      </div>
      
      <div className={approveStyles.disbursementAmounts}>
        <span className={approveStyles.value}><span className={approveStyles.label}>Total Benefit</span>: {state.amount}</span>
        <span className={approveStyles.value}><span className={approveStyles.label}>Allocated</span>: {distributed}</span>
        <span className={`${approveStyles.value} ${state.unallocated && approveStyles.required}`}><span className={approveStyles.label}>Unallocated</span>: {state.unallocated}</span>
      </div>
      
      <div>
        {state.monthlyAmounts.map((amount, i) => <>
          <TextField key={i} className={`${styles.paddedHorizontally} ${styles.spaced}`} variant={"standard"} type={"number"} disabled={!onChange}
                     label={`${Intl.DateTimeFormat(undefined, {month: "long", year: "numeric"}).format(addMonths(state.start, i))}`} 
                     onChange={e => onChange?.(state.monthlyAmounts.map((v, index) => index === i ? Number(e.target.value) : v))}
                     value={amount} />
        </>)}
      </div>
    </Grid>
  );
};

export {
  ApplicationDisbursements
};