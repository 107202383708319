import styles from "styles/application.module.scss";

import { Grid, Typography } from "@mui/material";
import { fullWidth } from "library";
import React from "react";
import { IconInfoCircle } from "@tabler/icons";
import { useTheme } from "@mui/material/styles";

const ClientWarnings = () => {
  const {palette: {primary}} = useTheme();
  
  return (
    <Grid container className={`${styles.applicationJourney}`}>
        <Grid item {...fullWidth}>
            <Typography variant={"h3"}>Warnings</Typography>
        </Grid>
        <Grid item {...fullWidth} className={styles.noContent}>
          <IconInfoCircle color={primary.main} />
          <span>Recipient warning notifications has not been activated.</span>
          <span>Functionality to come soon.</span>
        </Grid>
    </Grid>
  );
};

export {
  ClientWarnings
};