import styles from "../styles/letterPreview.module.scss";

import { PpulusLoader } from "./household";
import { useDispatch, useSelector } from "../store";
import { useEffect, useState } from "react";
import { getLetter, ILetterKey } from "../store/slices";
import { TextArea } from "./TextArea";
import { Button } from "@mui/material";
import { IconDownload } from "@tabler/icons";
import { downloadFileBlob } from "library";

type LetterPreviewProps = {
    letterKey: ILetterKey;
    disabled: boolean;
    onNoteChange: (value: string) => void;
}

const LetterPreview = ({ letterKey, disabled, onNoteChange }: LetterPreviewProps) => {
    const template = letterKey.letterTemplate;

    const dispatch = useDispatch();
    const { loading, items: { [template]: letterPreview } } = useSelector(s => s.letters);
    const [note, setNote] = useState("");

    useEffect(() => {
        const getLetterPdf = dispatch(getLetter(letterKey));

        return () => getLetterPdf.abort();
    }, [dispatch, letterKey]);

    const updateNoteText = (noteValue: string) => {
        setNote(noteValue);
        onNoteChange(noteValue);
    }

    const download = async () => {
        await dispatch(getLetter({ ...letterKey, override: true }))
            .unwrap()
            .then(letterPreviews => {
                downloadFileBlob(letterPreviews[template].value.fileBlob, letterPreviews[template].value.fileName);
            });
    };

    return (
        <>
            <div className={`${styles.enhancedBackground} ${styles.roundedCorners}`}>
                <div className={styles.header}>
                    Notification Letter Preview
                </div>
                <div>
                    {loading
                        ? <div className={styles.loader}><PpulusLoader /></div>
                        : !letterPreview?.value
                            ? <>Template has not been setup.</>
                            : <>
                                <iframe title={"letter"} src={`${URL.createObjectURL(letterPreview.value.fileBlob)}#toolbar=0&navpanes=0&scrollbar=0`} allowFullScreen width="100%" height="300px"></iframe>
                                <TextArea disabled={disabled} label={"Special Note"} value={note} onChange={updateNoteText} rows={12} />
                            </>}
                </div>
            </div>
            {letterPreview?.value &&
            <div className={styles.downloadButtons}>
                <Button color={"primary"} variant={"contained"} startIcon={<IconDownload/>} className={styles.button} disabled={loading} onClick={() => download()}>
                    DOWNLOAD LETTER (PDF)
                </Button>
                
            </div>}
        </>
    );
};

export {
    LetterPreview
}