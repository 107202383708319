import styles from "styles/gridTable.module.scss";

import GridTable, { GridTableProps } from "components/GridTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import { NonDigitalRow } from "./NonDigital.Rows";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { getNonDigitalCommunication, initialState, markAsSent, setNonDigitalFilter, setNonDigitalPage, setNonDigitalSort, viewDocument } from "store/slices";
import { PpulusGridFilterModel, PpulusGridSortItem } from "types/grid";
import { GridButtons } from "components";
import { CorrespondenceStatus } from "library";

const NonDigitalList = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {loading, items: nonDigitalCommunication, page, pageSize, count} = useSelector(s => s.nonDigital);
  
	const [document, setDocument] = useState<Omit<NonDigitalRow, "link">>();

	useEffect(() => {
		dispatch(getNonDigitalCommunication(initialState));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);
  
	const closeDialog = () => setDocument(undefined);

	const downloadAndConfirm = useCallback(async (document: Omit<NonDigitalRow, "link">) => {
		await dispatch(viewDocument(document.documentId));
		setDocument(document);
	}, [dispatch]);
  
	const columns = useMemo(() => [
		...Object.values(NonDigitalRow.Columns(navigate)),
		{
			field: null, header: null, size: 200, renderCell: row => row.status
				? <GridButtons text={"View"}>
					{[{text: "View", action: () => dispatch(viewDocument(row.documentId))}]}
				</GridButtons>
				: <GridButtons text={"View and Send"}>
        	{[
        		{text: "In Person", action: () => downloadAndConfirm({...row, status: CorrespondenceStatus.InPerson})},
        		{text: "By Mail", action: () => downloadAndConfirm({...row, status: CorrespondenceStatus.Mailed})}
        	]}
        </GridButtons>
		}
	], [navigate, downloadAndConfirm, dispatch]);

	const onFilter = useCallback((model: PpulusGridFilterModel) => {
		dispatch(setNonDigitalFilter({items: model.items}));
	}, [dispatch]);

	const onPage = useCallback((newPage: number, newPageSize: number) => {
		if (newPage === page && newPageSize === pageSize) return;
		dispatch(setNonDigitalPage({ page: newPage, pageSize: newPageSize }));
	}, [dispatch, page, pageSize]);
  
	const onSort = useCallback((sort: PpulusGridSortItem) => {
		dispatch(setNonDigitalSort(sort));
	}, [dispatch]);
  
	const gridTableProps: GridTableProps<NonDigitalRow> = {
		exportCsvEnabled: false,
		datasource: nonDigitalCommunication.map(NonDigitalRow.From),
		count,
		initialPageSize: 15,
		page: page ?? 0,
		loading,
		onFilter: onFilter,
		onPage: onPage,
		onSort: onSort,
		exportFileNamePrefix: "DisbursementsList",
		defaultFilterValue: [],
		columns: columns,
		userFiltered: false,
		rowClassName: styles.row,
		pageSizeOptionsEnabled: false
	};
  
	return (
		<Grid container columnSpacing={4}>
			<Grid item width={"100%"}>
				<GridTable {...gridTableProps} />
			</Grid>
			<SendCorrespondenceDialog isOpen={!!document} docId={document?.documentId} docStatus={document?.status} docDescription={`${document?.description} for ${document?.relatesTo.type} ${document?.relatesTo.description}`} closeDialog={closeDialog}/>
		</Grid>
	);
};

type SendCorrespondenceDialogProps = {
	isOpen: boolean;
	docId?: string;
	docStatus?: CorrespondenceStatus;
	docDescription?: string;
	closeDialog: () => void;
};

const SendCorrespondenceDialog = ({ isOpen, docId, docStatus, docDescription, closeDialog }: SendCorrespondenceDialogProps) => {
	const dispatch = useDispatch();

	const markCorrespondence = async () => {
		await dispatch(markAsSent({ id: docId!, status: docStatus! }));
		closeDialog();
	};

	return (
		<Dialog open={isOpen}>
			<DialogTitle>{"Has the document printed?"}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					This will mark the following document as <span className={"bold"}>{docStatus}</span>:
					<ul>
						<li>{docDescription}</li>
					</ul>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant={"outlined"} onClick={closeDialog}>It did not print</Button>
				<Button variant={"contained"} onClick={markCorrespondence}>Yes, ready to send</Button>
			</DialogActions>
		</Dialog>
	);
};

export {
	NonDigitalList,
	SendCorrespondenceDialog
};