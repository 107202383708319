import styles from "styles/client.module.scss";
import emailStyles from "styles/emailPreview.module.scss";

import { Card, Grid, Modal } from "@mui/material";
import { GridButtons, PpulusLoader } from "components";
import GridTable, { GridTableProps } from "components/GridTable";
import { CommunicationPreference, CorrespondenceRecord, CorrespondenceStatus, fullWidth } from "library";
import EmailRow from "library/EmailRow";
import { useCallback, useEffect, useState } from "react";
import { dispatch, useDispatch, useSelector } from "store";
import { getClientEmails, viewDocument } from "store/slices";
import { PpulusColumn } from "types/grid";
import { SendCorrespondenceDialog } from "views/nonDigital/NonDigital.List";

type ClientEmailsProps = {
	clientCode: string | undefined;
}

const ClientEmails = ({clientCode}: ClientEmailsProps) => {
	const dispatch = useDispatch();

	useEffect(() => {
		if (!clientCode) return;

		dispatch(getClientEmails(clientCode));
	}, [clientCode, dispatch]);
	
	return (
		<EmailGrid exportFileName={"ClientEmailList"} />
	);
};

type EmailGridProps = {
	exportFileName: string;
}

const EmailGrid = ({exportFileName}: EmailGridProps) => {
	const [emailPreview, setEmailPreview] = useState<CorrespondenceRecord>();
	const {loading, items} = useSelector(s => s.correspondences);
	const [document, setDocument] = useState<CorrespondenceRecord>();

	const downloadAndConfirm = useCallback(async (row: CorrespondenceRecord) => {
		await dispatch(viewDocument(row.id));
		setDocument(row);
	}, []);

	const buttons = (row: CorrespondenceRecord) =>
		<div>
			{row.type === CommunicationPreference.Paper
				? row.status === CorrespondenceStatus.Generated
					? <GridButtons text={"View and Send"}>
						{[
							{ text: "In Person", action: () => downloadAndConfirm({ ...row, status: CorrespondenceStatus.InPerson }) },
							{ text: "By Mail", action: () => downloadAndConfirm({ ...row, status: CorrespondenceStatus.Mailed }) }
						]}
					</GridButtons>
					: <GridButtons text={"View"}>
						{[{ text: "View", action: () => dispatch(viewDocument(row.id)) }]}
					</GridButtons>
				: <GridButtons text={"View"}>{[{ text: "View   ", action: () => setEmailPreview(row) }]}</GridButtons>
			}
		</div>;

	const columns: PpulusColumn<CorrespondenceRecord>[] = [
		...EmailRow.Columns,
		{
			field: null,
			header: null,
			minSize: 300,
			renderCell: row => buttons(row)
		}
	];

	const gridTableProps: GridTableProps<CorrespondenceRecord> = {
		exportCsvEnabled: true,
		height: "400px",
		datasource: items,
		count: items.length,
		loading,
		initialPageSize: 15,
		page: 0,
		exportFileNamePrefix: exportFileName,
		defaultFilterValue: EmailRow.Filter,
		columns: columns,
		userFiltered: false,
		onRowDoubleClick: setEmailPreview
	};

	return (
		<Card className={`${styles.content} ${styles.formDetail} ${styles.emailGrid}`}>
			{loading
				? <PpulusLoader />
				: <Grid container>
					<Grid item {...fullWidth}>
						<GridTable {...gridTableProps} />
					</Grid>
				</Grid>}
			<Modal open={!!emailPreview} onClose={() => setEmailPreview(undefined)}>
				<Grid container className={`${styles.modalContainer} ${emailStyles.enhancedBackground} ${emailStyles.roundedCorners}`}>
					<div>
						<strong>Subject:</strong> {emailPreview?.subject}
					</div>
					<p dangerouslySetInnerHTML={{ __html: emailPreview?.message ?? "" }} />
				</Grid>
			</Modal>
			<SendCorrespondenceDialog isOpen={!!document} docId={document?.id} docStatus={document?.status} docDescription={document?.subject} closeDialog={() => setDocument(undefined)}/>
		</Card>
	);
};

export {
	ClientEmails,
	EmailGrid
};
