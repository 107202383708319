import styles from "styles/application.module.scss";

import { Button, Grid, Typography } from "@mui/material";
import { CorrespondenceTabs, DateInput, PpulusLoader, TextArea } from "components";
import { addDays, addMonths, Application, EmailTemplate, fullWidth, hasError, LetterTemplate, OfferApplicationState, oneThirdWidth } from "library";
import { useCallback, useEffect, useState } from "react";
import { dispatch, useSelector } from "store";
import { loadSettings } from "store/slices/settings";
import { ApplicationProgramSelection, ApplicationProgramValues } from "./Application.Approve";
import { ApplicationDisbursements } from "./Application.Disbursements";

type ApplicationOfferProps = {
    value: Application;
    onClose: () => void;
    onSendOffer: (value: OfferApplicationState) => Promise<void>;
}

const ApplicationOffer = ({ value, onClose, onSendOffer }: ApplicationOfferProps) => {
    const [processing, setProcessing] = useState(false);
	  const {loading, settings} = useSelector(state => state.settings);

    const [state, setState] = useState<OfferApplicationState>(new OfferApplicationState({
        program: [...(value.programs ?? [])].shift() ?? "",
        specialMessage: "",
        notes: "",
        start: addMonths(new Date(), 1),
        programDuration: value.isGbv ? value.programDetails.durationInMonths : 12,
        offerAcceptanceDueDate: settings && addDays(new Date(), settings.onOfferNumberOfDays ?? 7),
        averageMonthlyAmount: value.amounts[[...(value.programs ?? [])].shift() ?? ""]?.amount,
        sendEmail: true,
        isGbv: value.isGbv
    }));
    
    const set = useCallback((newValue: Partial<OfferApplicationState>) => {
        setState(new OfferApplicationState({ 
            ...state, 
            ...newValue,
            amount: state.amount,
            monthlyAmounts: !!newValue.programDuration 
              ? undefined
              : state.monthlyAmounts
        }));
    }, [state]);

    useEffect(() => {
        if (!loading) return;

        dispatch(loadSettings())
            .unwrap()
            .then(settings => set({ offerAcceptanceDueDate: addDays(new Date(), settings.onOfferNumberOfDays ?? 7) }));;
    }, [loading, set]);
    
    const sendOffer = () => {
        const validated = state.validate();
        set(validated);
        if (hasError(validated) || !onSendOffer) return;

        setProcessing(true);
        onSendOffer(state).finally(() => setProcessing(false));
    };
    
    const application = {
        approvedProgram: {
            name: state.program!,
            startDate: state.start,
            amount: state.amount ?? 0,
            monthlyAmounts: state.monthlyAmounts,
            endDate: addMonths(state.start, value.programDetails.durationInMonths ?? 12)!
        },
        offerAcceptanceDueDate: state.offerAcceptanceDueDate
    };

    return (
        <Grid container className={styles.modalContainer}>
            {processing ? <PpulusLoader /> : <>
                <Grid item {...fullWidth}>
                    <div className={styles.modalText}>
                        <Typography variant={"h3"}>Offer Benefit</Typography>
                        <br />
                        An email will be sent out to <a href={`mailto: ${value.applicant.email?.address}`}>{value.applicant.email?.address ?? ""}</a> notifying the applicant that their application has been resulted in an offer.
                        Please complete the following details to initiate the application offer.
                    </div>
                </Grid>

                <ApplicationProgramSelection value={value} selectedProgram={state.program} set={set} />
                <ApplicationProgramValues state={state} processing={processing} set={set} />

                <Grid container className={styles.modalText}>
                    <Grid item {...oneThirdWidth}>
                        {loading ? <PpulusLoader small/> : <DateInput label={"Acceptance Due Date"} value={state.offerAcceptanceDueDate} disabled={processing} error={state.errorState.offerAcceptanceDueDate} onChange={v => set({ offerAcceptanceDueDate: v ?? new Date() })} />}
                    </Grid>
                </Grid>
                
                <ApplicationDisbursements state={state} onChange={monthlyAmounts => setState(new OfferApplicationState({...state, monthlyAmounts}))} />

                <CorrespondenceTabs emailTemplate={EmailTemplate.OfferApplication} 
                    letterTemplate={LetterTemplate.OfferApplication} 
                    application={application}
                    program={value.approvedProgram} 
                    communicationPreference={value.communicationPreference} 
                    processing={processing} 
                    overrideEmail={true}
                    dependencies={[state.program, state.offerAcceptanceDueDate]}
                    set={set}/>

                <div className={`${styles.modalText} ${styles.fullWidth}`}>
                    <p className={styles.note}>Please note that an "Agreement" file will be generated and emailed to the applicant. A copy of the file will be saved in the documents section of the application.</p>
                    <TextArea label={"Notes"} disabled={processing} value={state.notes} onChange={v => set({ notes: v })} rows={5} />
                </div>

                <div className={styles.modalButtons}>
                    <Button variant={"outlined"} disabled={processing} onClick={onClose}>CANCEL</Button>
                    <Button variant={"contained"} disabled={processing} onClick={sendOffer}>SEND OFFER</Button>
                </div>
            </>}
        </Grid>
    );
}

export { ApplicationOffer };
