import styles from "styles/application.module.scss";
import approveStyles from "styles/applicationApprove.module.scss";

import { Button, ButtonGroup, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import {IconCircleX, IconFileCheck} from "@tabler/icons";
import {CorrespondenceTabs, DateInput, FormValueDisplay, PpulusLoader, TextArea} from "components";
import {
  addMonths,
  Application,
  ApplicationContent,
  ApproveApplicationState,
  DateDisplay,
  EmailTemplate,
  fullWidth,
  hasError,
  LetterTemplate,
  oneThirdWidth,
  parseNumber,
  ProgramDetails,
  ProgramDuration
} from "library";
import {ReactNode, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "store";
import {getClient, getMatchingClients} from "store/slices";
import { ApplicationDisbursements } from "./Application.Disbursements";

interface IApproveApplicationProps {
    value: Application;
    onCancel: () => void;
    onApprove: (value: ApproveApplicationState) => Promise<void>;
}

const ApproveApplication: (props: IApproveApplicationProps) => ReactNode = ({value, onCancel, onApprove}) => {
    const [processing, setProcessing] = useState(false);
    const [state, setState] = useState<ApproveApplicationState>(new ApproveApplicationState({
        program: value.approvedProgram?.name,
        specialMessage: "",
        notes: "",
        start: value.approvedProgram?.startDate,
        amount: value.approvedProgram?.amount,
        monthlyAmounts: value.approvedProgram?.monthlyAmounts,
        averageMonthlyAmount: value.approvedProgram?.monthlyAmount,
        sendEmail: true,
        programDuration: value.approvedProgram?.programLength,
        isGbv: value.isGbv
    }));

    const set = (newValue: Partial<ApproveApplicationState>) => setState(new ApproveApplicationState({...state, ...newValue}));

    const approve = () => {
        const validated = state.validate();
        set(validated);
        if (hasError(validated) || !onApprove) return;

        setProcessing(true);
        onApprove(state).finally(() => setProcessing(false));
    };
    
    return (
        <Grid container className={styles.modalContainer}>
            <Grid item {...fullWidth}>
                <div className={styles.modalText}>
                    <Typography variant={"h3"}>Approve Application</Typography>
                    <br/>
                    An email will be sent out to <a href={`mailto: ${value.applicant.email?.address}`}>{value.applicant.email?.address ?? ""}</a> notifying the applicant that their application has been approved.
                    Please complete the following details to complete the processing of this approved application.
                </div>
            </Grid>

            <ApplicationProgramSelection value={value} readonly={true} selectedProgram={state.program} set={set} />

            <PotentialClientGrid error={state.errorState.clientCode} disabled={processing} clientCode={state.clientCode} applicantSin={value.applicant.socialInsuranceNumber} onChange={code => set({clientCode: code})}/>
            
            <ApplicationProgramValues state={state} readonly={true} processing={processing} set={set} />
            <ApplicationDisbursements state={state} />

            <CorrespondenceTabs emailTemplate={EmailTemplate.ApproveApplication}
                letterTemplate={LetterTemplate.ApproveApplication}
                application={{ approvedProgram: value.approvedProgram }}
                program={value.approvedProgram}
                communicationPreference={value.communicationPreference}
                processing={processing}
                overrideEmail={true}
                dependencies={[state.start, state.amount]}
                set={set} />

            <div className={`${styles.modalText} ${styles.fullWidth}`}>
                <TextArea label={"Notes"} disabled={processing} value={state.notes} onChange={v => set({notes: v})} rows={5}/>
            </div>

            {!value.isGbv && <div className={`${styles.modalText} ${styles.fullWidth}`}>
                <div className={approveStyles.header}>Annual Review Schedule</div>
                <p>Please note that this client will be scheduled for an annual review due date of <span className={approveStyles.keepTogether}>{DateDisplay.Long(addMonths(state.start, 12))}</span></p>
            </div>}

            <div className={styles.modalButtons}>
                <Button variant={"outlined"} disabled={processing} startIcon={<IconCircleX/>} onClick={onCancel}>Cancel</Button>
                <Button variant={"contained"} disabled={processing} startIcon={<IconFileCheck/>} onClick={approve}>Approve</Button>
            </div>
        </Grid>
    );
}

const PotentialClientGrid: (props: { error?: string, disabled: boolean, clientCode?: string, applicantSin: string | undefined, onChange: (code?: string) => void }) => ReactNode = ({error, disabled, clientCode, applicantSin, onChange}) => {
    const dispatch = useDispatch();
    const [pending, setPending] = useState(true);
    const {loadingClients: clientsLoading, clients} = useSelector(s => s.matchingClients);

    useEffect(() => {
        dispatch(getMatchingClients(4)).then(() => setPending(false));
    }, [dispatch]);

    const clientRows = useMemo(() => [
        <PotentialClientRow key={-1} className={`${approveStyles.row} ${clients?.length ? approveStyles.odd : ""}`}
                            selectable
                            selected={!clientCode}
                            onSelect={() => onChange(undefined)}
                            id={"New Client"}
                            name={""}
                            dateOfBirth={""}
                            email={""}
                            sin={""}/>
        , ...(clients ?? [])
            .map((c, i) => <PotentialClientRow key={i}
                                               selectable={!disabled}
                                               selected={c.code === clientCode}
                                               className={`${approveStyles.row} ${i % 2 ? approveStyles.odd : ""}`}
                                               id={c.code}
                                               name={c.primaryContact?.displayName}
                                               dateOfBirth={DateDisplay.Standard(c.primaryContact?.dateOfBirth)}
                                               email={c.primaryContact?.email.address}
                                               sin={c.primaryContact?.socialInsuranceNumber === applicantSin ? "Yes" : "No"}
                                               onSelect={() => onChange(c.code)}
            />)
        ,
    ], [clientCode, disabled, applicantSin, clients, onChange]);

    return (
        <div className={approveStyles.clientContainer}>
            <div className={approveStyles.header}>Potential Client Matches</div>
            <div className={approveStyles.clientGrid}>
                <PotentialClientRow className={approveStyles.header} id={"Client ID"} name={"Name"} dateOfBirth={"DOB"} email={"Email"} sin={"SIN Match?"}/>
                {clientsLoading || pending
                    ? <div className={approveStyles.empty}><PpulusLoader/></div>
                    : clientRows}
            </div>
        </div>
    );
};

interface IPotentialClientRowProps {
    className?: string;
    selectable?: boolean;
    selected?: boolean;
    id: string | undefined;
    name: string | undefined;
    dateOfBirth: string;
    email: string | undefined;
    sin: string;
    onSelect?: () => void;
}

const PotentialClientRow: (props: IPotentialClientRowProps) => ReactNode = ({className, selectable, selected, id, name, dateOfBirth, email, sin, onSelect}) => {
    return (
        <>
            <div className={className}>{selectable && <Radio checked={selected} onChange={() => onSelect?.()}/>}</div>
            <div className={className}>{id ?? "-"}</div>
            <div className={className}>{name ?? "-"}</div>
            <div className={className}>{dateOfBirth ?? "-"}</div>
            <div className={className}>{email ?? "-"}</div>
            <div className={className}>{sin}</div>
        </>
    );
};

const ApprovedApplication: (props: { value: Application, onClose: () => void }) => ReactNode = ({value, onClose}) => {
    const dispatch = useDispatch();
    const [pending, setPending] = useState(true);
    const {loading, item: client} = useSelector(s => s.client);

    useEffect(() => {
        if (!value.clientCode) return;

        dispatch(getClient(value.clientCode!)).then(() => setPending(false));
    }, [dispatch, value.clientCode]);

    const newClient = useMemo(() => (client?.applications.length ?? 0) <= 1, [client]);

    return (
        <Grid container className={styles.modalContainer}>
            {loading || pending ? <PpulusLoader/> : <>
                <Typography variant={"h1"}>Recipient File {newClient ? "Created" : "Updated"}</Typography>
            <p className={styles.modalText}>
                {client && `A recipient file with unique identifier ${client.code} (${client.primaryContact.displayName}) has been ${newClient ? "created" : "updated"} for this approved ${ApplicationContent[value!.type].heading.toLowerCase()}.`}
            </p>
            <ButtonGroup className={styles.modalButtons}>
                <Button variant={"contained"} onClick={onClose}>Close</Button>
            </ButtonGroup>
            </>}
        </Grid>
    );
};

const PaymentInformationRequired: (props: { onClose: () => void }) => ReactNode = ({onClose}) => {
    return (
        <Grid container className={styles.modalContainer}>
            <Typography variant={"h1"}>Payment Information Required</Typography>
            <p className={styles.modalText}>
                This application cannot be approved at this moment because Payment Details have not been specified.
                Please input the banking (EFT) information in order to proceed with application approval.
            </p>
            <ButtonGroup className={styles.modalButtons}>
                <Button variant={"contained"} onClick={onClose}>Close</Button>
            </ButtonGroup>
        </Grid>
    )
}

const ApplicationProgramSelection = ({ value, readonly, selectedProgram, set }: { value: Application, readonly?: boolean, selectedProgram: string | undefined, set: (newValue: { program: string, amount: number }) => void }) => {
    return (
        <div className={approveStyles.programGrid}>
            <div>
                <div className={approveStyles.header}>Applicant qualifies for the following programs</div>
                <ul>{value.programs.map(p => <li key={p}>{p}</li>)}</ul>
            </div>

            <div>
                <div className={approveStyles.header}>Program to Assign to Applicant</div>
                {readonly
                ? <div className={approveStyles.selectedProgram}>{selectedProgram}</div>
                : <RadioGroup name={"programs"}>
                    {value.programs.map(p => <FormControlLabel key={p} label={p}
                        control={<Radio value={p} checked={selectedProgram === p}
                            onChange={e => set({
                                program: e.target.value,
                                amount: value.amounts[e.target.value]?.amount ?? 0
                            })} />} />
                    )}
                </RadioGroup>}
            </div>
        </div>);
};

type ApplicationProgramValuesProps = {
  state: ApproveApplicationState;
  readonly?: boolean;
  processing: boolean;
  set: (amount: Partial<ApproveApplicationState>) => void;
}

const ApplicationProgramValues = ({ state, readonly, processing, set }: ApplicationProgramValuesProps) => {
    const updateProgramDuration = (value: ProgramDuration) => set({programDuration: new ProgramDetails({duration: value, durationInMonths: undefined}).durationInMonths ?? 0})
    const programDuration = useMemo(() => new ProgramDetails({durationInMonths: state.programDuration}).duration, [state]);
    
    return (
        <Grid container className={styles.modalText}>
            <Grid item {...oneThirdWidth}>
                {readonly
                    ? <FormValueDisplay label={"Start Date"} value={DateDisplay.Standard(state.start)} labelWidth={fullWidth}/>
                    : <DateInput label={"Start Date"} value={state.start} disabled={processing} error={state.errorState.start} onChange={v => set({ start: v ?? new Date() })} />}
            </Grid>
            <Grid item {...oneThirdWidth}>
                {readonly
                    ? <FormValueDisplay label={"End Date"} value={DateDisplay.Standard(addMonths(state.start, state.programDuration))} labelWidth={fullWidth}/>
                    : <TextField className={styles.paddedHorizontally} variant={"standard"} disabled label={"End Date"} value={DateDisplay.Standard(addMonths(state.start, state.programDuration))} />}
            </Grid>
            {state.program !== "GBV" ?
              <Grid item {...oneThirdWidth}>
                {readonly
                    ? <FormValueDisplay label={"Monthly Subsidy Amount"} value={`${state.monthlyAmount}`} labelWidth={fullWidth} />
                    : <TextField className={styles.paddedHorizontally}
                                 disabled={readonly || processing}
                                 variant={"standard"} label={"Monthly Subsidy Amount"} type={"number"}
                                 error={!!state.errorState.amount} helperText={state.errorState.amount}
                                 value={state.monthlyAmount ?? ""}
                                 onChange={v => set({ averageMonthlyAmount: parseNumber(v.target.value) ?? 0, amount: undefined })} />}
              </Grid> :
              <Grid item {...oneThirdWidth}>
              {readonly
                  ? <FormValueDisplay label={"Program duration"} value={`${state.programDuration} months`} labelWidth={fullWidth} />
                  : <FormValueDisplay labelWidth={fullWidth} label={"Preferred Duration"} value={<>
                      <Select fullWidth value={programDuration} variant={"standard"} disabled={processing} onChange={v => updateProgramDuration(v.target.value as ProgramDuration)}>
                        {Object.entries(ProgramDuration).map(([k, v]) => <MenuItem key={k} value={v}>{v}</MenuItem>)}
                      </Select>
                      {programDuration === "Other" && <TextField type={"number"} variant={"standard"} value={state.programDuration} onChange={v => set({programDuration: Number(v.target.value)})} />}
                  </>} />
                }
              </Grid>}
        </Grid>);
}

export {
    ApproveApplication, 
    ApproveApplicationState, 
    ApprovedApplication,
    PaymentInformationRequired,
    ApplicationProgramSelection,
    ApplicationProgramValues
};