import countStyles from "styles/counts.module.scss";
import styles from "styles/disbursements.module.scss";

import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Toolbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { PpulusLoader } from "components";
import { CountDisplay } from "components/CountDisplay";
import GridTable, { GridTableProps } from "components/GridTable";
import { CurrencyDisplay, PaymentStatus, Period } from "library";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { exportPayFile, generatePayFile, getDisbursements, payCheque } from "store/slices/disbursements";
import { PpulusColumn } from "types/grid";
import { groupBy } from "../../utils/util";
import { ChequeRow } from "./ChequeRow";
import { DisbursementRow } from "./Disbursement.Row";
import { DisbursementsMultiple } from "./Disbursements.Multiple";
import { DisbursementOptionForm, DisbursementOptions } from "./Disbursements.Options";

enum DialogType {
	PendingPayments,
	PayFileGenerationStarted,
	MultiplePayments,
	PayFileGenerated
}

type DisbursementsGenerateProps = {
		chequesOnly?: boolean;
};

const DisbursementsGenerate = ({chequesOnly}: DisbursementsGenerateProps) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {defaultProgram} = useSelector(s => s.programs);
	const {loading, items: disbursements, pageSize} = useSelector(s => s.disbursements);
	const [state, setState] = useState<DisbursementOptions>({program: defaultProgram, period: Period.Create(new Date())});
	const [exporting, setExporting] = useState(false);
	const [dialog, setDialog] = useState<DialogType>();
	const [generationStates, setGenerationStates] = useState<DisbursementOptions[]>([]);

	const set = (value: Partial<DisbursementOptions>) => setState(current => ({...current, ...value}));

	const isGenerating = useMemo(() => generationStates.some(s => s.program.shortName === state.program.shortName && s.period.toString() === state.period.toString()), [state, generationStates]);

	useEffect(() => {
		const updateDisbursements = setTimeout(() => {
			dispatch(getDisbursements({
				page: 0, pageSize: 0, filter: {
					items: [
						{columnField: "bankDetails.chequePayments", value: `${!!chequesOnly}`, operatorValue: "equals"},
						{columnField: "period", value: state.period.toString(), operatorValue: "startsWith"},
						{columnField: "program", value: state.program.shortName, operatorValue: "equals"},
						{columnField: "status", value: `${PaymentStatus.Upcoming},${PaymentStatus.Failed},${PaymentStatus.OnHold}`, operatorValue: "isAnyOf"}
					]
				}
			}));
		}, 500);

		return () => clearTimeout(updateDisbursements);
	}, [state, dispatch, chequesOnly]);
	
	const rows = useMemo(() => !chequesOnly
		? disbursements.map(DisbursementRow.From)
		: disbursements.map(ChequeRow.From), [chequesOnly, disbursements]);

	const totals = useMemo(() => ({
		clients: disbursements.length ? new Set(disbursements.filter(d => d.status !== PaymentStatus.OnHold).map(d => d.clientCode)).size : "-",
		organizations: disbursements.length
			? new Set(disbursements
				.filter(d => d.status !== PaymentStatus.OnHold)
				.filter(d => d.bankDetails.isOrganization)
				.map(d => `${d.bankDetails.isOrganization}-${d.bankDetails.transit}-${d.bankDetails.account}`)).size
			: "-",
		disbursements: disbursements.length ? disbursements.filter(d => d.status !== PaymentStatus.OnHold).length : "-",
		amount: disbursements.filter(d => d.status !== PaymentStatus.OnHold).reduce((a, c) => a + c.amount, 0),
		onHold: disbursements.filter(d => d.status === PaymentStatus.OnHold).reduce((a, c) => a + c.amount, 0),
		multiples: Object.values(groupBy(disbursements.filter(d => d.status !== PaymentStatus.OnHold), "clientCode")).filter(g => g.length > 1).length
	}), [disbursements]);

	const exportAsFile = useCallback(() => {
		setExporting(true);
		dispatch(exportPayFile({...state, values: rows.filter(r => r.status !== PaymentStatus.OnHold) as DisbursementRow[]}))
			.unwrap()
			.finally(() => setExporting(false));
	}, [state, dispatch, setExporting, rows]);

	const onGeneratePayFile = useCallback(() => {
		if (disbursements.some(d => [PaymentStatus.Pending].includes(d.status))) {
			setDialog(DialogType.PendingPayments);
			return;
		}
		if (!isGenerating) {
			setGenerationStates(status => [...status, state]);

			dispatch(generatePayFile(state))
				.unwrap()
				.then(payFile => !!payFile ? setDialog(DialogType.PayFileGenerationStarted) : setDialog(DialogType.PayFileGenerated))
				.finally(() => setGenerationStates(status => status.filter(s => s.program !== state.program && s.period !== state.period)));
		}
	}, [isGenerating, state, dispatch, disbursements]);

	const markChequeAsPaid = useCallback((row: ChequeRow) => {
		dispatch(payCheque(row.payment));
	}, [dispatch]);

	const columns: PpulusColumn<any>[] = useMemo(() => !chequesOnly
		? Object.entries(DisbursementRow.Columns(navigate)).filter(([k]) => !["period", "cheque", "paymentDateDisplay"].includes(k)).map(([,v]) => v)
		: [
			...Object.values(ChequeRow.Columns),
			{
				field: null, header: null, width: 140, renderCell: (row: ChequeRow) => 
					row.status !== PaymentStatus.Paid && <Button onClick={() => markChequeAsPaid(row)}>Update to Paid</Button>
			}
		], [navigate, chequesOnly, markChequeAsPaid]);
	
	const gridTableProps: GridTableProps<any> = {
		exportCsvEnabled: true,
		datasource: rows,
		count: rows.length,
		loading,
		initialPageSize: pageSize ?? 15,
		exportFileNamePrefix: "DisbursementsList",
		columns: columns,
		userFiltered: false,
		defaultFilterValue: [],
		isFilterable: false,
		gridDataModel: "client",
		enableToolbar: true,
	};

	return (
		<Grid container width={"100%"} className={styles.content}>
			<DisbursementOptionForm value={state} onChange={set}/>

			{!chequesOnly &&
						<div className={styles.paymentHeader}>
								Preview
							<div className={styles.right}>
								<Button color={"secondary"} disabled={exporting} className={styles.button} variant={"contained"} onClick={exportAsFile}>
									{!exporting && !isGenerating ? "Export" : <PpulusLoader noPad/>}
								</Button>
								<Button className={styles.button} variant={"contained"} onClick={onGeneratePayFile}>
									{isGenerating ? <PpulusLoader noPad/> : "Generate Payment File"}
								</Button>
							</div>
						</div>}

			<div className={styles.section}>
				<div className={countStyles.totals}>
					<CountDisplay value={`${totals.clients}`} label={"Recipients"}/>
					<CountDisplay value={`${totals.organizations}`} label={"Organizations"}/>
					<CountDisplay value={`${totals.disbursements}`} label={"Disbursements"}/>
					<CountDisplay value={CurrencyDisplay(totals.amount)} label={"Total Payment"}/>
					<CountDisplay value={CurrencyDisplay(totals.onHold)} label={"On Hold"} backgroundColor={theme.palette.grey["500"]}/>
					<CountDisplay value={`${totals.multiples}`} label={"Recipients with multiple payments"} backgroundColor={theme.palette.orange.dark} onClick={totals.multiples ? () => setDialog(DialogType.MultiplePayments) : undefined} />
				</div>
				<GridTable {...gridTableProps}/>
			</div>

			<DisbursementsGenerateDialog dialogType={dialog} program={state?.program.shortName} period={state?.period} onClose={() => setDialog(undefined)} />
		</Grid>
	);
};

type DisbursementsGenerateDialogProps = {
	dialogType?: DialogType;
	program?: string;
	period?: Period;
	onClose: () => void;
}

const DisbursementsGenerateDialog = ({ dialogType, program, period, onClose }: DisbursementsGenerateDialogProps) => {
	return (
		<>
			<Dialog open={dialogType===DialogType.PayFileGenerated} onClose={onClose}>
				<DialogTitle>{`Generation of a new pay file is prohibited for this program as there is a previously generated pay file that has not been closed off (Pay Register File uploaded or Cancelled).`}</DialogTitle>
				<DialogActions>
					<Button onClick={onClose}>Ok</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={dialogType===DialogType.PendingPayments} onClose={onClose}>
				<DialogTitle>{"Payment File generation is not possible while there are still files pending."}</DialogTitle>
				<DialogActions>
					<Button onClick={onClose}>Ok</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={dialogType===DialogType.PayFileGenerationStarted} onClose={onClose}>
				<DialogTitle>{"Pay File Generation Started"}</DialogTitle>
				<DialogContent>
					<p>An excel spreadsheet export + Pay File are currently in queue for generation. Please go to the Pay File in Pay File Management to download both files.</p>
					<p>Depending on the number of disbursements, generation takes anywhere from 30 seconds to 2 minutes.</p>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose}>Ok</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={dialogType===DialogType.MultiplePayments} fullWidth maxWidth={"xl"} onClose={onClose}>
				<Toolbar>
					<IconButton edge={"start"} onClick={onClose}><CloseIcon /></IconButton>
				</Toolbar>
				<DialogTitle>{`Recipients with multiple payments - ${program}, ${period} period`}</DialogTitle>
				<DialogContent >
					<p>
						The following recipients have 2 or more payments for the selected program and period.
						Please review to ensure that these are correct payments prior to generating the pay file.
					</p>
					<DisbursementsMultiple />
				</DialogContent>
			</Dialog>
		</>
	)
};

export {
	DisbursementsGenerate
};
