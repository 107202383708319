import styles from "styles/application.module.scss";

import React, {ReactNode, useCallback, useState} from "react";
import { Application, ApplicationDocuments, ApplicationType, CommunicationPreference, EmailTemplate, halfWidth, IDocuments, LetterTemplate, SupportingDocumentsTypeMap } from "library";
import {Button, Checkbox, FormControlLabel, Grid, Typography} from "@mui/material";
import {CorrespondenceTabs, PpulusLoader} from "components";
import {IconBrandTelegram, IconCircleX} from "@tabler/icons";

interface IRequestDocumentsProps {
    application: Application;
    communicationPreference?: CommunicationPreference;
    onCancel: () => void;
    onApprove: (value: Partial<IDocuments>, specialMessage: string, sendEmail: boolean) => void;
}

const RequestDocuments: (props: IRequestDocumentsProps) => ReactNode = ({application, communicationPreference, onCancel, onApprove}) => {
    const [selections, setSelections] = useState<ApplicationDocuments>(ApplicationDocuments.DefaultFor(application));
    const [state, setState] = useState({sendEmail: true, specialMessage: ""});
    const [processing, setProcessing] = useState(false);
    const isForStandard = application.type === ApplicationType.Standard;
    
    const set = useCallback((value: Partial<IDocuments>) => setSelections(new ApplicationDocuments({...selections, ...value})), [selections, setSelections]);
    const setCorrespondence = (newValue: Partial<{ specialMessage: string, notes: string, sendEmail: boolean }>) => setState(s => ({...s, ...newValue}));

    const submit = () => {
        setProcessing(true);
        onApprove(selections, state.specialMessage, state.sendEmail);
    } 

    return (
        <Grid container className={styles.modalContainer}>
            <div className={styles.modalText}>
                <Typography variant={"h3"}>Request Supporting Documents</Typography>
                <br/>
                An email will be sent out to <a href={`mailto: ${application.applicant.email?.address}`}>{application.applicant.email?.address ?? ""}</a> requesting that the {isForStandard ? "following" : ""} supporting documents be uploaded.
            </div>
            {isForStandard && <Grid container className={styles.modalText}>
                {Object.entries(SupportingDocumentsTypeMap).map(([k, v]) => (
                    <Grid key={k} item {...halfWidth}>
                        <FormControlLabel control={<Checkbox value={k} onChange={(_, v) => set({[k]: {required: v}})}/>} label={v} checked={(selections[k]).required}/>
                    </Grid>
                ))}
            </Grid>}

            <CorrespondenceTabs emailTemplate={EmailTemplate.RequestDocuments}
                letterTemplate={LetterTemplate.SupportingDocuments}
                communicationPreference={communicationPreference}
                processing={processing}
                set={setCorrespondence} />
                    
            <div className={styles.modalButtons}>
                <Button variant={"outlined"} disabled={processing} startIcon={<IconCircleX/>} onClick={onCancel}>Cancel</Button>
                {processing 
                    ? <div className={styles.loader}><PpulusLoader /></div>
                    :<Button variant={"contained"} disabled={processing} startIcon={<IconBrandTelegram/>} onClick={submit}>Request Documents</Button>}
            </div>
        </Grid>
    );
};

export {
    RequestDocuments
};