import { ProgramDuration } from "library";
import { createErrorState, ErrorState, IErrorState } from "../common";

interface IProgramDetails {
	haveReferral: boolean;
	referringOrganization?: string;
	referralName?: string;
	referralPhone?: string;
	referralEmail?: string;
	referralRole?: string;
	duration?: ProgramDuration;
	durationInMonths?: number;
}

class ProgramDetails implements Partial<IProgramDetails>, IErrorState<Partial<IProgramDetails>> {
	readonly haveReferral: boolean;
	readonly referringOrganization?: string;
	readonly referralName?: string;
	readonly referralPhone?: string;
	readonly referralEmail?: string;
	readonly referralRole?: string;
	readonly duration?: ProgramDuration;
	readonly durationInMonths?: number;
	private readonly validated: boolean;
	
	constructor(from?: Partial<IProgramDetails>) {
		this.haveReferral = from?.haveReferral ?? false;
		
		if (this.haveReferral) {
			this.referringOrganization = from?.referringOrganization ?? "";
			this.referralName = from?.referralName ?? "";
			this.referralPhone = from?.referralPhone ?? "";
			this.referralEmail = from?.referralEmail ?? "";
			this.referralRole = from?.referralRole ?? "";
		}
		
		this.duration = from?.duration;
		this.durationInMonths = from?.durationInMonths;
		
		switch (this.duration) {
			case "6 Months":
				this.durationInMonths = 6;
				break;
			case "12 Months":
				this.durationInMonths = 12;
				break;
			default:
				this.durationInMonths = from?.durationInMonths;
				break;
		}
		
		if (!this.duration)
			switch (this.durationInMonths) {
				case 12:
					this.duration = ProgramDuration.Year;
					break;
				case 6:
					this.duration = ProgramDuration.HalfYear;
					break;
				default:
					this.duration = ProgramDuration.Other;
			}
		
		this.validated = (from as ProgramDetails)?.validated;
	}
	
	validate(): ProgramDetails {
		return new ProgramDetails({...this, validated: true});
	}
	
	get errorState(): ErrorState<Partial<IProgramDetails>> {
		return this.validated ? createErrorState<Partial<IProgramDetails>>({
			duration: !this.duration ? "Please specify a value." : "",
			durationInMonths: this.durationInMonths && (this.durationInMonths < 0 || this.durationInMonths > 12) ? "Specify a value between 1 and 12" : "",
			referralEmail: this.haveReferral && !this.referralPhone && !this.referralEmail ? "Phone or Email required." : "",
			referralPhone: this.haveReferral && !this.referralPhone && !this.referralEmail ? "Phone or Email required." : "",
			referralName: this.haveReferral && !this.referralName ? "Please specify name." : ""
		}) : {};
	}
}

export {ProgramDetails};
export type {IProgramDetails};