import {FronteggProvider} from "@frontegg/react";
import {type ContextOptions} from "@frontegg/types/ContextOptions";
import {FC, ReactNode} from "react";

const contextOptions: ContextOptions = {
	baseUrl: `https://${process.env.REACT_APP_AUTH_REALM}.frontegg.com`,
	clientId: `${process.env.REACT_APP_AUTH_CLIENT}`,
	appId: `${process.env.REACT_APP_AUTH_APP_ID}`,
	tenantResolver: () => ({tenant: 'civida'})
};

export const FronteggAuthProvider: FC<{children: ReactNode}> = ({children}) => {
	return (
		<FronteggProvider contextOptions={contextOptions} hostedLoginBox authOptions={{keepSessionAlive: true}} entitlementsOptions={{enabled: true}} >
			{children}
		</FronteggProvider>
	)
};