import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { YesNo } from "library";
import { ReactNode, useCallback } from "react";

const YesNoButton: (props: { value: YesNo | undefined, disabled?: boolean, onChange: (value: YesNo | undefined) => void, optional?: boolean }) => ReactNode = ({ value, disabled, onChange, optional = false }) => {
	const onClick = useCallback((newValue: YesNo) => {
		onChange(optional ? value !== newValue ? newValue : undefined : newValue);
	}, [value, onChange, optional]);

	return (
		<ToggleButtonGroup color={"primary"} disabled={disabled} fullWidth>
			<ToggleButton value={YesNo.Yes} fullWidth selected={value === YesNo.Yes} onClick={() => onClick(YesNo.Yes)}>{YesNo.Yes}</ToggleButton>
			<ToggleButton value={YesNo.No} fullWidth selected={value === YesNo.No} onClick={() => onClick(YesNo.No)}>{YesNo.No}</ToggleButton>
		</ToggleButtonGroup>
	);
};

export {
	YesNoButton
};