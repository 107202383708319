import { Application, DateDisplay, getDateFrom } from "library";
import { PpulusColumn, PpulusFilterValue } from "types/grid";

class ClientApplicationsRow {
	public static Columns: PpulusColumn<ClientApplicationsRow>[] = [
		{field: "id", header: "ID", renderCell: row => row.id},
		{field: "programs", header: "Applying For", renderCell: row => row.programs},
		{field: "score", header: "Score", maxSize: 100, renderCell: row => `${row.score}`},
		{field: "submitted", header: "Submitted", renderCell: row => DateDisplay.Long(row.submitted)},
		{field: "status", header: "Status", renderCell: row => row.status},
	];

	public static Filter: PpulusFilterValue = [];

	constructor(readonly id: string, readonly programs: string[], readonly score: number, readonly submitted: Date, readonly status: string) {
	}

	static From(value: Application): ClientApplicationsRow {
		return new ClientApplicationsRow(
			value.code,
			value.programs,
			value.pointScore.total,
			getDateFrom(value.createdOn),
			`${value.status}${value.approvedProgram ? ` - ${value.approvedProgram.name}` : ""}`
		);
	}
};

class ClientAnnualReviewRow {
	public static Columns: PpulusColumn<ClientAnnualReviewRow>[] = [
		...ClientApplicationsRow.Columns.filter(c => c.field !== "submitted"),
		{field: "submitted", header: "Created Date", renderCell: row => DateDisplay.Long(row.submitted)},
		{field: "dueDate", header: "Due Date", renderCell: row => DateDisplay.Long(row.dueDate)}
	];

	public static Filter: PpulusFilterValue = [];

	constructor(readonly id: string, readonly programs: string[], readonly score: number, readonly submitted: Date, readonly status: string, readonly dueDate: Date | undefined) {
	}

	static From(value: Application): ClientAnnualReviewRow {
		return {
			...ClientApplicationsRow.From(value),
			dueDate: getDateFrom(value.dueDate)
		};
	}
}

export {ClientApplicationsRow, ClientAnnualReviewRow};