import styles from "styles/application.module.scss";

import { DateDisplay, fullWidth, Journey } from "library";
import {Grid, Typography} from "@mui/material";
import React, {ReactNode, useCallback, useMemo, useState} from "react";
import {Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator} from "@mui/lab";
import {
	IconArrowBackUp,
	IconBrandCashapp,
	IconBuildingBank,
	IconCheck,
	IconDotsCircleHorizontal,
	IconFileStack,
	IconGift,
	IconHomeBolt,
	IconHomeCancel,
	IconHomeCheck,
	IconHomeEdit,
	IconHomeMinus,
	IconHomePlus,
	IconId, IconInfoCircle,
	IconMailForward,
	IconMailOpened,
	IconPhoneCalling,
	IconThumbDown,
	IconUserPlus
} from "@tabler/icons";
import {Theme, useTheme} from "@mui/material/styles";
import {IconCalendarDollar} from "../icons/IconCalendarDollar";

const Icons: Record<string, (theme: Theme) => ReactNode> = {
	"Basic information updated": () => <IconId/>,
	"Payment details updated": theme => <IconBrandCashapp color={theme.palette.warning.dark}/>,
	"Supporting documents updated": () => <IconFileStack/>,
	"Income details updated": () => <IconCalendarDollar/>,
	"Housing details updated": () => <IconHomeEdit/>,
	"Other contact consent": () => <IconPhoneCalling/>,
	"Created": () => <IconHomePlus/>,
	"Submitted": theme => <IconHomeBolt color={theme.palette.primary.main}/>,
	"Eligible": () => <IconHomeBolt/>,
	"Supporting documents requested": () => <IconMailForward/>,
	"Supporting documents received": () => <IconMailOpened/>,
	"Viewed Banking Details": theme => <IconBuildingBank color={theme.palette.error.main}/>,
	"Primary Assignment to": theme => <IconUserPlus color={theme.palette.info.light}/>,
	"Secondary Assignment to": theme => <IconUserPlus color={theme.palette.warning.dark}/>,
	"Approved": theme => <IconHomeCheck color={theme.palette.primary.dark}/>,
	"Cancelled": theme => <IconHomeCancel color={theme.palette.warning.dark}/>,
	"Rejected": theme => <IconHomeMinus color={theme.palette.error.main}/>,
	"Offer Made": () => <IconGift />,
	"Offer Rescinded": () => <IconArrowBackUp />,
	"Offer Accepted": () => <IconCheck />,
	"Offer Declined": () => <IconThumbDown />,
};

const JourneyForm: (props: {title: string, journey: Journey}) => ReactNode = ({title, journey}) => {
	const theme = useTheme();
	const [showAll, setShowAll] = useState(false);

	const toggleShowAll = useCallback(() => setShowAll(value => !value), [setShowAll]);

	const entries = useMemo(() => showAll ? journey.entries : [...journey.entries].slice(-4), [journey, showAll]);
	
	return (
		<Grid container className={`${styles.applicationJourney}`}>
			<Grid item {...fullWidth}>
				<Typography variant={"h3"}>{title}</Typography>
			</Grid>
			{!journey.entries.length
				? <Grid item {...fullWidth} className={styles.noContent}><IconInfoCircle color={theme.palette.primary.main} /> No records to display.</Grid>
				: (<Timeline>
					{journey.entries.length > 4 && <TimelineItem>
						<TimelineOppositeContent></TimelineOppositeContent>
						<TimelineSeparator>
							<IconDotsCircleHorizontal color={theme.palette.grey["400"]} onClick={toggleShowAll} className={`${styles.journeyItem} ${styles.link}`}/>
							<TimelineConnector/>
						</TimelineSeparator>
						<TimelineContent>
							<span className={`${styles.journeyItem} ${styles.link}`} onClick={toggleShowAll}>{showAll ? "See less" : "See more"}</span>
						</TimelineContent>
					</TimelineItem>}
					{entries.length > 0 && entries.map((e, i) => (
						<TimelineItem key={i}>
							<TimelineOppositeContent>
								{DateDisplay.LongTime(e.actionDate)}
								{e.user && <span className={styles.journeyUser}>- {e.user}</span>}
							</TimelineOppositeContent>
							<TimelineSeparator>
								{Icons[[...e.eventType.split(" ")].slice(0, 3).join(" ")]?.(theme) ?? <TimelineDot/>}
								{i !== entries.length - 1 && <TimelineConnector/>}
							</TimelineSeparator>
							<TimelineContent>
								<span className={styles.journeyItem}>{e.eventType}</span>
							</TimelineContent>
						</TimelineItem>
					))}
					</Timeline>)}
				</Grid>	
		);
};

export {
	JourneyForm
};