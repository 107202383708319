import {createSlice} from "@reduxjs/toolkit";
import {createAppAsyncThunk} from "./asyncThunk";
import agent, { FilterOptions } from "api/agent";
import { addDays, CommunicationPreference, DateDisplay, isEmpty, NonDigitalCommunication } from "library";
import { RootState } from "../index";
import { PpulusPagination } from "../../types/grid";
import { GridFilterModel, GridSortItem } from "@mui/x-data-grid";
import { markAsSent } from "./correspondences";

const getCurrentOptions = (getState: () => RootState) => {
  const {nonDigital: {filter, page, pageSize, sort}} = getState();
  return {filter, page, pageSize, sort};
};

const setNonDigitalPage = createAppAsyncThunk("nonDigital/setPage", async (pagination: PpulusPagination, {dispatch, getState}) => {
  const options = {...getCurrentOptions(getState), ...{page: pagination.page, pageSize: pagination.pageSize}};
  await dispatch(getNonDigitalCommunication(options));
  return options;
});

const setNonDigitalSort = createAppAsyncThunk("nonDigital/sort", async (sort: GridSortItem | undefined, {dispatch, getState}) => {
  const options = {...getCurrentOptions(getState), sort};
  await dispatch(getNonDigitalCommunication(options));
  return options;
});

const setNonDigitalFilter = createAppAsyncThunk("nonDigital/filter", async (filter: GridFilterModel, {dispatch, getState}) => {
  const options = {...getCurrentOptions(getState), ...{
    filter: isEmpty(filter?.items) ? initialState.filter : {...staticFilter, ...filter}
  }, page: 0};
  await dispatch(getNonDigitalCommunication(options));
  return options;
});

const getNonDigitalCommunication = createAppAsyncThunk("nonDigital/load", async (options: FilterOptions, {getState}) => {
  const filter = {items: [...options.filter?.items ?? getCurrentOptions(getState).filter?.items ?? [], ...staticFilter.items]};
  const listOptions = {...options, filter};
  const fromApi = await agent.Correspondence.get(listOptions);
  return {...listOptions, items: fromApi.items.map(NonDigitalCommunication.from), count: fromApi.count};
});

const staticFilter = {
  items: [
    {columnField: "type", operatorValue: "equals", value: CommunicationPreference.Paper}
  ]
}

const initialFilter = {
  items: [
    ...staticFilter.items,
    {columnField: "createdOn", operatorValue: "onOrAfter", value: DateDisplay.PeriodNumeric(addDays(new Date(), -30))}
  ]
};

const initialState: FilterOptions & { loading: boolean, items: NonDigitalCommunication[], count: number} = {
  loading: true,
  page: 0,
  pageSize: 15,
  sort: {field: "period", sort: "asc"},
  filter: initialFilter,
  items: [],
  count: 0
};

const nonDigital = createSlice({
  name: "nonDigital",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setNonDigitalPage.fulfilled, (state, {payload}) => ({...state, ...payload}));
    builder.addCase(setNonDigitalSort.fulfilled, (state, {payload}) => ({...state, ...payload}));
    builder.addCase(setNonDigitalFilter.fulfilled, (state, {payload}) => ({...state, ...payload}));
    builder.addCase(getNonDigitalCommunication.pending, (state) => ({...state, loading: true, items: []}));
    builder.addCase(getNonDigitalCommunication.fulfilled, (_, {payload}) => ({loading: false, items: payload.items, count: payload.count}));
    builder.addCase(getNonDigitalCommunication.rejected, (state, {error}) => {
      console.error(error);
      return { ...state, loading: false };
    });
    builder.addCase(markAsSent.fulfilled, (state, {payload}) => ({
        ...state,
        items: state.items.map(e => e.id === payload.id ? new NonDigitalCommunication({...e, sentType: payload.status}) : e)
    }));
  }
});

export default nonDigital.reducer;

export {getNonDigitalCommunication, setNonDigitalPage, setNonDigitalSort, setNonDigitalFilter, initialState};