import styles from "styles/application.module.scss";
import approveStyles from "styles/applicationApprove.module.scss";

import { Button, ButtonGroup, Grid, TextField, Typography } from "@mui/material";
import { IconCircleX, IconFileCheck } from "@tabler/icons";
import { DateInput, PpulusLoader, TextArea } from "components";
import { EmailPreview } from "components/EmailPreview";
import { Application, ApproveApplicationState, DateDisplay, EmailTemplate, addMonths, fullWidth, hasError, oneThirdWidth, parseNumber } from "library";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "store";
import { getClient } from "store/slices";

interface IApproveReviewProps {
    value: Application;
    onCancel: () => void;
    onApprove: (value: ApproveApplicationState) => Promise<void>;
}

const ApproveReview: (props: IApproveReviewProps) => ReactNode = ({value, onCancel, onApprove}) => {
    const [processing, setProcessing] = useState(false);
    const [state, setState] = useState<ApproveApplicationState>(new ApproveApplicationState({
        type: value.type,
        clientCode: value.clientCode,
        program: [...(value.programs ?? [])].shift() ?? "",
        specialMessage: "",
        notes: "",
        start: value.dueDate,
        sendEmail: true,
        averageMonthlyAmount: value.amounts[[...(value.programs ?? [])].shift() ?? ""]?.amount
    }));

    const emailKey = useMemo(() => ({
        emailTemplate: EmailTemplate.ApproveReview,
        override: true,
        application: {
            approvedProgram: {
                name: state.program!,
                startDate: state.start,
                amount: state.amount ?? 0,
                monthlyAmounts: state.monthlyAmounts,
                endDate: addMonths(state.start, 12)!
            }
        }
    }), [state]);

    const set = (newValue: Partial<ApproveApplicationState>) => setState(new ApproveApplicationState({...state, ...newValue}));

    const approve = () => {
        const validated = state.validate();
        set(validated);
        if (hasError(validated) || !onApprove) return;

        setProcessing(true);
        onApprove(state).finally(() => setProcessing(false));
    };
    
    return (
        <Grid container className={styles.modalContainer}>
            {processing ? <PpulusLoader /> : <>
                <Grid item {...fullWidth}>
                    <div className={styles.modalText}>
                        <Typography variant={"h3"}>Approve Annual Review</Typography>
                        <br />
                        An email will be sent out to <a href={`mailto: ${value.applicant.email?.address}`}>{value.applicant.email?.address ?? ""}</a> notifying the client that their annual review has been approved.
                        Please complete the following details to complete the processing of this approved annual review.
                    </div>
                </Grid>
                <Grid item {...fullWidth}>
                    <div className={styles.modalText}>
                        <div className={approveStyles.header}>Client is reviewed for the following program(s)</div>
                        <ul>{value.programs.map(p => <li key={p}>{p}</li>)}</ul>
                    </div>
                </Grid>
                <Grid item {...fullWidth}>
                    <div className={styles.modalText}>
                        <div className={approveStyles.header}>Client file will be updated</div>
                        <p>Data from this annual review will be used to update the Client's file post annual review approval.</p>
                    </div>
                </Grid>
                <Grid container className={styles.modalText}>
                    <Grid item {...oneThirdWidth}>
                        <DateInput label={"Renewal Start Date"} disabled={processing} value={state.start} error={state.errorState.start} onChange={v => set({ start: v ?? new Date() })} />
                    </Grid>
                    <Grid item {...oneThirdWidth}>
                        <TextField className={styles.paddedHorizontally} variant={"standard"} disabled label={"End Date"} value={DateDisplay.Standard(addMonths(state.start, 12))} />
                    </Grid>
                    <Grid item {...oneThirdWidth}>
                        <TextField className={styles.paddedHorizontally}
                            disabled={processing}
                            variant={"standard"} label={"Monthly Subsidy Amount"} type={"number"}
                            error={!!state.errorState.amount} helperText={state.errorState.amount}
                            value={state.monthlyAmount ?? ""}
                            onChange={v => set({ averageMonthlyAmount: parseNumber(v.target.value) })} />
                    </Grid>
                </Grid>

                <EmailPreview emailKey={emailKey} disabled={processing} dependencies={[state.amount, state.start]} onNoteChange={v => set({ specialMessage: v })} onSendEmailChanged={v => set({ sendEmail: v })} />

                <div className={`${styles.modalText} ${styles.fullWidth}`}>
                    <TextArea label={"Notes"} value={state.notes} disabled={processing} onChange={v => set({ notes: v })} rows={5} />
                </div>

                <div className={`${styles.modalText} ${styles.fullWidth}`}>
                    <div className={approveStyles.header}>Annual Review Schedule</div>
                    <p>Please note that this client will be scheduled for an annual review due date of <span className={approveStyles.keepTogether}>{DateDisplay.Long(addMonths(state.start, 12))}</span></p>
                </div>

                <div className={styles.modalButtons}>
                    <Button variant={"outlined"} disabled={processing} startIcon={<IconCircleX />} onClick={onCancel}>Cancel</Button>
                    <Button variant={"contained"} disabled={processing} startIcon={<IconFileCheck />} onClick={approve}>Approve</Button>
                </div>
            </>}
        </Grid>
    );
}
const ApprovedAnnualReview: (props: { value: Application, onClose: () => void }) => ReactNode = ({value, onClose}) => {
  const dispatch = useDispatch();
  const [pending, setPending] = useState(true);
  const {loading, item: client} = useSelector(s => s.client);

  useEffect(() => {
    if (!value.clientCode) return;

    dispatch(getClient(value.clientCode!)).then(() => setPending(false));
  }, [dispatch, value.clientCode]);

  return (
    <Grid container className={styles.modalContainer}>
      {loading || pending ? <PpulusLoader/> : <>
        <Typography variant={"h1"}>Recipient Program Renewed </Typography>
        <p className={styles.modalText}>
          {client && `Recipient file with unique identifier ${client.code} (${client.primaryContact.displayName}) has been updated based on this approved annual review.`}.
        </p>
        <ButtonGroup className={styles.modalButtons}>
          <Button variant={"contained"} onClick={onClose}>Close</Button>
        </ButtonGroup>
      </>}
    </Grid>
  );
};
export {
    ApproveReview, ApprovedAnnualReview
};
