import {isNumeric} from "./common";
import { FeatureFlag } from "../types/featureFlag";
import { WebHook } from "../types/webhook";

enum Bank {
    RoyalBank = "RBC"
}

class PaymentFileSettings {
	public readonly routingHeader: string;
	public readonly sequentialFileNumber: boolean;
	public readonly fileNumber: string;
	public readonly clientNumber: string;
	public bank: Bank;

	constructor(from?: Partial<PaymentFileSettings>) {
		this.routingHeader = from?.routingHeader ?? "";
		this.sequentialFileNumber = from?.sequentialFileNumber ?? false;
		this.fileNumber = (this.sequentialFileNumber
			? (isNumeric(from?.fileNumber ?? "") ? from!.fileNumber ?? "1" : "")
			: (from?.fileNumber ?? "TEST"))
			.slice(0, 4);
		this.clientNumber = from?.clientNumber ?? "";
		this.bank = from?.bank ?? Bank.RoyalBank;
	}
}

class GeneralSettings {
	public readonly paymentSettings: PaymentFileSettings;
	public readonly onOfferNumberOfDays?: number;
	public readonly featureFlags: FeatureFlag[];
	public readonly webhooks: WebHook[];

	constructor(from?: Partial<GeneralSettings>) {
		this.paymentSettings = new PaymentFileSettings(from?.paymentSettings);
		this.onOfferNumberOfDays = from?.onOfferNumberOfDays;
		this.featureFlags = from?.featureFlags ?? [];
		this.webhooks = from?.webhooks ?? [];
	}
}

export {
	PaymentFileSettings,
	GeneralSettings
};