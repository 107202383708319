import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {ServiceType, ServiceTypeParams} from "../../../models/servicetype";
import agent from "../../../api/agent";
import {MetaData} from "../../../models/pagination";
import {RootState} from "../../../store";

interface ServiceTypeState {
  serviceTypesLoaded: boolean;
  status: string;
  serviceTypeParams: ServiceTypeParams;
  metaData: MetaData | null;
}

const servicetypeAdapter = createEntityAdapter<ServiceType>({
  selectId: (servicetype) => servicetype.serviceTypeId
});

function getAxiosParams(servicetypeParams: ServiceTypeParams) {
  const params = new URLSearchParams();
  params.append('pageNumber', servicetypeParams.pageNumber.toString());
  params.append('pageSize', servicetypeParams.pageSize.toString());
  params.append('orderBy', servicetypeParams.orderBy);

  if (servicetypeParams.searchTerm) params.append('searchTerm', servicetypeParams.searchTerm);
  return params;
}

export const fetchServiceTypesAsync = createAsyncThunk<ServiceType[], void, {state: RootState}>(
  'servicetype/fetchServiceTypesAsync',
  async (_, thunkAPI) => {
    const params = getAxiosParams(thunkAPI.getState().serviceType.serviceTypeParams);
    try {
      const response = await agent.ServiceTypes.list(params);
      thunkAPI.dispatch(setMetaData(response.metaData));
      return response.items;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({error: error.data})
    }
  }
)

export const fetchServiceTypeAsync = createAsyncThunk<ServiceType, string>(
  'servicetype/fetchServiceTypeAsync',
  async (serviceTypeId, thunkAPI) => {
    try {
      return await agent.ServiceTypes.details(serviceTypeId);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({error: error.data})
    }
  }
)

function initParams() {
  return {
    pageNumber: 1,
    pageSize: 10,
    orderBy: 'name'
  }
}

export const servicetypeSlice = createSlice({
  name: 'servicetype',
  initialState: servicetypeAdapter.getInitialState<ServiceTypeState>({
    serviceTypesLoaded: false,
    status: 'idle',
    serviceTypeParams: initParams(),
    metaData: null
  }),
  reducers: {
    setServiceTypeParams: (state, action) => {
      state.serviceTypesLoaded = false;
      state.serviceTypeParams = {...state.serviceTypeParams, ...action.payload, pageNumber: 1};
    },
    setPageNumber: (state, action) => {
      state.serviceTypesLoaded = false;
      state.serviceTypeParams = {...state.serviceTypeParams, ...action.payload};
    },
    setMetaData: (state, action) => {
      state.metaData = action.payload;
    },
    resetServiceTypeParams: (state) => {
      state.serviceTypeParams = initParams();
    },
    setServiceType: (state, action) => {
      servicetypeAdapter.upsertOne(state, action.payload);
      state.serviceTypesLoaded = false;
    },
    removeServiceType: (state, action) => {
      servicetypeAdapter.removeOne(state, action.payload);
      state.serviceTypesLoaded = false;
    }
  },
  extraReducers: (builder => {
    builder.addCase(fetchServiceTypesAsync.pending, (state) => {
      state.status = 'pendingFetchServiceTypes';
    });
    builder.addCase(fetchServiceTypesAsync.fulfilled, (state, action) => {
      servicetypeAdapter.setAll(state, action.payload);
      state.status = 'idle';
      state.serviceTypesLoaded = true;
    });
    builder.addCase(fetchServiceTypesAsync.rejected, (state, action) => {
      state.status = 'idle';
    });
    builder.addCase(fetchServiceTypeAsync.pending, (state) => {
      state.status = 'pendingFetchServiceType';
    });
    builder.addCase(fetchServiceTypeAsync.fulfilled, (state, action) => {
      servicetypeAdapter.upsertOne(state, action.payload);
      state.status = 'idle';
    });
    builder.addCase(fetchServiceTypeAsync.rejected, (state, action) => {
      state.status = 'idle';
    });
  })
})

export const serviceTypeSelectors = servicetypeAdapter.getSelectors((state: RootState) => state.serviceType);

export const {setServiceTypeParams, resetServiceTypeParams, setMetaData, setPageNumber, setServiceType, removeServiceType} = servicetypeSlice.actions;

