import styles from "styles/client.module.scss";

import React, {ReactNode, useCallback, useEffect, useState} from "react";
import {useDispatch} from "store";
import {Card, Grid} from "@mui/material";
import {AuditDetails, BankingDetails, ContactDetail, EmergencyContacts, HousingDetails, JourneyForm, MemberDetail} from "components";
import {ApplicantInformation, ApplicationDocuments, Client, halfWidth} from "library";
import {setClient, viewClientFinancialInfo} from "store/slices";
import { ClientWarnings } from "./Client.Warnings";

const ClientForm: (props: {value: Client | undefined, readonly: boolean}) => ReactNode = ({value, readonly}) => {
	const dispatch = useDispatch();
	const [state, setState] = useState(value);

	useEffect(() => {
		setState(value);
	}, [value]);

	const save = useCallback(async (newValues: Partial<Client>) => {
		if (!state) return;

		await dispatch(setClient({clientValue: new Client({...state, ...newValues})})).unwrap().then(setState);
	}, [dispatch, setState, state]);

	const onView = useCallback(async () => {
        dispatch(viewClientFinancialInfo()).unwrap().then();
    }, [dispatch]);

	if (!state)
		return <div>Client could not be found</div>;

	return (
		<Card>
			<Grid container className={styles.content}>
				<MemberDetail label={"General"} value={state.primaryContact} requireSin readonly={readonly} className={styles.formDetail} onChange={async v => save({primaryContact: v as ApplicantInformation})}/>
				<ContactDetail value={state.otherContacts} readonly={readonly} onChange={async v => save({otherContacts: v})}/>

				<Grid container className={`${styles.unbordered} ${styles.paddedContainer}`}>
					<Grid item {...halfWidth}>
						<JourneyForm title={"Client Journey"} journey={state.journey}/>
					</Grid>
					<Grid item {...halfWidth}>
						<ClientWarnings />
					</Grid>
				</Grid>

				<HousingDetails value={state.housing} readonly={readonly} onChange={async v => save({housing: v})}/>
				<BankingDetails value={state.documents.bankInfo} readonly={readonly} onChange={v => save({documents: new ApplicationDocuments({...state?.documents, bankInfo: v})})} onView={onView}/>
				<EmergencyContacts readonly={readonly} value={state.otherContacts} onChange={async v => await save({otherContacts: v})}/>
			</Grid>

			<Grid container>
				<AuditDetails value={state}/>
			</Grid>
		</Card>
	);
};

export {
	ClientForm
};